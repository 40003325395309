import React, { Component } from 'react';
import { LinearProgress, Typography, Grid } from '@material-ui/core';

import FollowerCard from './FollowerCard';

class FollowersBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      followers: null,
      loading: true
    };
  }

  componentDidMount() {
    const { authUser } = this.props;

    if (authUser && authUser.followers && authUser.followers.length > 0) {
      this.getFollowers();

      return;
    }

    this.setState({
      loading: false
    });
  }

  getFollowers = async () => {
    const { authUser, firebase } = this.props;
    const followers = {};

    let query = firebase.users();
    query = query.where(
      firebase.fieldPath.documentId(),
      'in',
      authUser.followers
    );

    const followersRefs = await query.get();
    followersRefs.forEach(s => {
      followers[s.id] = s.data();
    });

    this.setState({
      followers,
      loading: false
    });
  };

  onFollowClick = (e, followerId) => {
    e.preventDefault();

    const { authUser } = this.props;
    const isFollowed = authUser.follows.includes(followerId);

    if (isFollowed) {
      this.doUnfollow(followerId);
      return;
    }

    this.doFollow(followerId);
  };

  doUnfollow = followerId => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const { followers } = this.state;

    const newUserFollows = authUser.follows.filter(id => id !== followerId);
    const newFollowerFollowers = followers[followerId].followers.filter(
      id => id !== authUser.uid
    );

    firebase
      .user(authUser.uid)
      .set(
        {
          follows: newUserFollows
        },
        { merge: true }
      )
      .then(() => {
        const updatedFollower = {
          ...followers[followerId],
          followers: newFollowerFollowers
        };
        onSetAuthUser({
          ...authUser,
          follows: newUserFollows
        });
        this.setState({
          followers: {
            ...followers,
            [followerId]: {
              ...updatedFollower
            }
          }
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });

    firebase
      .user(followerId)
      .set(
        {
          followers: newFollowerFollowers
        },
        { merge: true }
      )
      .then()
      .catch(err => {
        console.log('error: ', err);
      });
  };

  doFollow = followerId => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const { followers } = this.state;

    const newUserFollows = authUser.follows
      ? [...authUser.follows, followerId]
      : [followerId];

    const newFollowerFollowers = followers[followerId].followers
      ? [...followers[followerId].followers, authUser.uid]
      : [authUser.uid];

    firebase
      .user(authUser.uid)
      .set(
        {
          follows: newUserFollows
        },
        { merge: true }
      )
      .then(() => {
        const updatedFollower = {
          ...followers[followerId],
          followers: newFollowerFollowers
        };
        onSetAuthUser({
          ...authUser,
          follows: newUserFollows
        });
        this.setState({
          followers: {
            ...followers,
            [followerId]: {
              ...updatedFollower
            }
          }
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });

    firebase
      .user(followerId)
      .set(
        {
          followers: newFollowerFollowers
        },
        { merge: true }
      )
      .then()
      .catch(err => {
        console.log('error: ', err);
      });
  };

  render() {
    const { classes, authUser } = this.props;
    const { loading, followers } = this.state;
    const sortedFollowers = [];

    if (followers) {
      const userFollowers = [...authUser.followers];
      userFollowers.reverse().forEach(followerId => {
        sortedFollowers.push({
          uid: followerId,
          ...followers[followerId]
        });
      });
    }

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {!loading && (
          <div className={classes.root}>
            <Typography className={classes.pageTitle} variant="h1">
              People following you
            </Typography>
            {sortedFollowers.length === 0 && (
              <Typography
                className={classes.noFollowTitle}
                align="center"
                variant="body1">
                You don&apos;t have any followers yet
              </Typography>
            )}
            {sortedFollowers.length > 0 && (
              <Grid className={classes.followList} container>
                {sortedFollowers.map(
                  ({
                    uid,
                    avatar,
                    firstName,
                    lastName,
                    username,
                    memberStatus,
                    occupation,
                    description
                  }) => {
                    const fullName =
                      firstName && lastName
                        ? `${firstName} ${lastName}`
                        : username;

                    let status = memberStatus || '';
                    if (occupation) {
                      status =
                        status !== ''
                          ? `${status} | ${occupation}`
                          : `${occupation}`;
                    }

                    const isFollowed = authUser.follows.includes(uid);

                    return (
                      <Grid key={uid} item lg={4} md={12} xs={12}>
                        <FollowerCard
                          userId={uid}
                          avatar={avatar}
                          fullName={fullName}
                          status={status}
                          description={description}
                          isFollowed={isFollowed}
                          onFollowClick={e => this.onFollowClick(e, uid)}
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default FollowersBase;
