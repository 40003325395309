import React from 'react';
import Moment from 'react-moment';
import { Link as RouterLink } from 'react-router-dom';
import { Card, Grid, Avatar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { styles } from './Notification.style';
import { AUTHOR, ARTICLES } from '../../../constants/routes';

const NOTIFICATION_TYPE_MESSAGE = {
  response: 'a new response to the post',
  post: 'a new post'
};

const Notification = ({ classes, type, user, post, date }) => {
  return (
    <Card className={classes.root}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Moment className={classes.period} fromNow>
            {new Date(date.seconds * 1000)}
          </Moment>
        </Grid>
        <Grid item container spacing={2} alignItems="center" wrap="nowrap">
          <Grid item>
            <Avatar
              className={classes.avatar}
              src={user.avatar}
              alt={user.fullName}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.message}>
              <RouterLink to={`${AUTHOR}/${user.uid}`}>
                {user.fullName}
              </RouterLink>
              {` added ${NOTIFICATION_TYPE_MESSAGE[type]}: `}
              <RouterLink to={`${ARTICLES}/${post.uid}`}>
                {post.title}
              </RouterLink>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default withStyles(styles)(Notification);
