import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Snackbar
} from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';

import { withFirebase } from '../../Firebase';

const styles = theme => ({
  root: {},
  contentContainer: {},
  content: {
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5)
  },
  contentHeader: {
    display: 'flex',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  changeButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
});

const INITIAL_STATE = {
  passwordOld: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  openError: false,
  openSuccess: false,
  success: null
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOld, passwordOne } = this.state;
    const email = this.props.authUser.email;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, passwordOld)
      .then(() => {
        this.props.firebase
          .doPasswordUpdate(passwordOne)
          .then(() => {
            this.setState({
              passwordOld: '',
              passwordOne: '',
              passwordTwo: '',
              error: null,
              openError: false,
              success: {
                message: 'Password updated successfully'
              },
              openSuccess: true
            });
          })
          .catch(error => {
            this.setState({ error, openError: true });
          });
      })
      .catch(error => {
        this.setState({ error, openError: true });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      openError: false,
      openSuccess: false
    });
  };

  render() {
    const {
      passwordOld,
      passwordOne,
      passwordTwo,
      openError,
      error,
      openSuccess,
      success
    } = this.state;
    const { classes } = this.props;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOld === '' ||
      passwordOne === '' ||
      passwordTwo === '';

    return (
      <Grid className={classes.content} item lg={6} xs={12}>
        <Card>
          <form autoComplete="off" noValidate onSubmit={this.onSubmit}>
            <CardHeader title="Change Password" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="passwordOld"
                    label="Current Password"
                    name="passwordOld"
                    variant="outlined"
                    value={passwordOld}
                    type="password"
                    onChange={this.onChange}
                    margin="dense"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="passwordOne"
                    label="Password"
                    name="passwordOne"
                    variant="outlined"
                    value={passwordOne}
                    type="password"
                    onChange={this.onChange}
                    margin="dense"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="passwordTwo"
                    label="Confirm Password"
                    name="passwordTwo"
                    variant="outlined"
                    value={passwordTwo}
                    type="password"
                    onChange={this.onChange}
                    margin="dense"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isInvalid}
                className={classes.changeButton}>
                Change My Password
              </Button>
            </CardActions>
          </form>
        </Card>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          onClose={this.handleClose}
          autoHideDuration={3000}>
          <Alert variant="filled" severity="error" onClose={this.handleClose}>
            <AlertTitle>Error</AlertTitle>
            {error && error.message}
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSuccess}
          onClose={this.handleClose}
          autoHideDuration={3000}>
          <Alert variant="filled" severity="success" onClose={this.handleClose}>
            {success && success.message}
          </Alert>
        </Snackbar>
      </Grid>
    );
  }
}

PasswordChangeForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

// export default withFirebase(withStyles(styles)(PasswordChangeForm));

export default compose(
  connect(mapStateToProps),
  withFirebase
)(withStyles(styles)(PasswordChangeForm));
