export const styles = theme => ({
  root: {},
  label: {
    position: 'static',
    marginBottom: theme.spacing(1),
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    lineHeight: 1.3334,
    letterSpacing: 1.88
  },
  input: {
    padding: theme.spacing(1.5, 1.75),
    fontSize: 12,
    lineHeight: 1.3334,
    letterSpacing: 0.11,
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 4,
    '& input': {
      padding: 0
    }
  }
});

export default styles;
