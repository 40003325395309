import app from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    app.analytics();

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.fieldPath = app.firestore.FieldPath;
    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.timeStamp = app.firestore.Timestamp;

    /* Firebase APIs */

    this.auth = app.auth();
    this.store = app.firestore();
    this.storage = app.storage();

    // Get a reference to the storage service, which is used to create references in your storage bucket
    this.storage = app.storage();
    // Create a storage reference from our storage service
    this.storageRef = this.storage.ref();

    /* Social Sign In Method Provider */
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();

    this.messaging = null;
    if (app.messaging.isSupported()) {
      this.messaging = app.messaging();
      this.messaging.usePublicVapidKey(
        process.env.REACT_APP_MESSAGING_PUSH_CERT
      );

      // Handle token refresh
      this.messaging.onTokenRefresh(() => {
        this.messaging
          .getToken()
          .then(refreshedToken => {
            console.log('refreshedToken :>> ', refreshedToken);
          })
          .catch(err => {
            console.error('refreshedToken Error', err);
          });
      });

      this.messaging.onMessage(payload => {
        console.log('Message received :>> ', payload);
        // ...
      });
    }
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // FIRESTORE

  // *** User API ***

  user = uid => this.store.doc(`users/${uid}`);

  users = () => this.store.collection('users');

  // *** Article API ***

  article = uid => this.store.doc(`articles/${uid}`);

  articles = () => this.store.collection('articles');

  // *** Category API ***

  category = uid => this.store.doc(`categories/${uid}`);

  categories = () => this.store.collection('categories');

  // *** Tags API ***

  tag = uid => this.store.doc(`tags/${uid}`);

  tags = () => this.store.collection('tags');
}

export default Firebase;
