import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

import { Option, Options } from './moreOptions.style';

export const MoreOptions = ({
  size,
  anchorEl,
  onOptionsClick,
  onOptionClose,
  onEditClick,
  onDeleteClick
}) => {
  return (
    <React.Fragment>
      <IconButton size={size} aria-haspopup="true" onClick={onOptionsClick}>
        <MoreHoriz />
      </IconButton>
      <Options
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onOptionClose}>
        <Option onClick={onEditClick}>Edit</Option>
        <Option onClick={onDeleteClick}>Delete</Option>
      </Options>
    </React.Fragment>
  );
};

MoreOptions.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  anchorEl: PropTypes.object,
  onOptionsClick: PropTypes.func,
  onOptionClose: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func
};

MoreOptions.defaultProps = {
  size: 'medium'
};

export default MoreOptions;
