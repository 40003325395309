import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Grid,
  Avatar,
  Typography,
  Button,
  CardActionArea
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { AUTHOR } from '../../../constants/routes';
import { styles } from './FollowerCard.style';

const DEFAULT_AVATAR = '/images/default-avatar.png';

const FollowerCard = ({
  classes,
  userId,
  avatar,
  fullName,
  status,
  isFollowed,
  onFollowClick
}) => {
  return (
    <Card className={classes.root}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <CardActionArea
            disableRipple={true}
            component={RouterLink}
            to={`${AUTHOR}/${userId}`}>
            <Grid container wrap="nowrap" alignItems="center" spacing={1}>
              <Grid item>
                <Avatar alt="Avatar" className={classes.avatar} src={avatar} />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="h5">{fullName}</Typography>
                <Typography className={classes.status} component="span">
                  {status}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  id="follow-btn"
                  className={clsx({
                    [classes.followBtn]: true,
                    followed: isFollowed
                  })}
                  variant="outlined"
                  size="small"
                  onClick={onFollowClick}>
                  {isFollowed ? 'Following' : 'Follow'}
                </Button>
              </Grid>
            </Grid>
          </CardActionArea>
        </Grid>
      </Grid>
    </Card>
  );
};

FollowerCard.propTypes = {
  classes: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  description: PropTypes.string,
  isFollowed: PropTypes.bool,
  onFollowClick: PropTypes.func
};

FollowerCard.defaultProps = {
  avatar: DEFAULT_AVATAR,
  isFollowed: false
};

export default withStyles(styles)(FollowerCard);
