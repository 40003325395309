import React, { Component } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import {
  Card,
  Button,
  Grid,
  Avatar,
  Typography,
  CardContent,
  Paper,
  LinearProgress,
  Divider
} from '@material-ui/core';

import { TabContext } from '@material-ui/lab';

import { People, ThumbUp } from '@material-ui/icons';

import { ACCOUNT, FOLLOWERS, FOLLOWING } from '../../constants/routes';
import { withEmailVerification, withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import { changeUserCoins } from '../../common/Helpers';

import ArticleList from '../Articles/ArticleList';
import {
  styles,
  StyledTabPanel,
  StyledTab,
  StyledTabList
} from './Profile.style';

const DEFAULT_AVATAR = '/images/default-avatar.png';
const INITIAL_STATE = {
  savedArticles: [],
  usersArticles: [],
  currentArticleList: 'saved',
  error: null,
  loading: true,
  progress: 1
};

const ProfilePage = () => (
  <div>
    <Grid>
      <ProfileDetails />
    </Grid>
  </div>
);

class ProfileDetailsBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.getArticles();
  }

  async getArticles() {
    const { authUser, firebase } = this.props;
    const savedArticles = [];
    const usersArticles = [];

    if (authUser.savedArticles && authUser.savedArticles.length > 0) {
      let query = firebase.articles();
      query = query.where('status', '==', 'published');
      query = query.where(
        this.props.firebase.fieldPath.documentId(),
        'in',
        authUser.savedArticles
      );

      const articlesRef = await query.get();

      if (articlesRef.docs.length > 0) {
        for (const articleRef of articlesRef.docs) {
          const article = articleRef.data();

          const existingUser = savedArticles.filter(art => {
            if (article.contributorRef === art.contributorRef) {
              return art;
            }

            return false;
          });

          if (existingUser.length > 0) {
            article.contributor = existingUser[0].contributor;
          } else {
            const userRef = await this.props.firebase
              .user(article.contributorRef)
              .get();
            article.contributor = userRef.data();
          }

          savedArticles.push({ ...article, uid: articleRef.id });
        }
      }
    }

    let query = firebase.articles();
    query = query.where('status', '==', 'published');
    query = query.where('contributorRef', '==', authUser.uid);

    const articlesRef = await query.get();

    if (articlesRef.docs.length > 0) {
      for (const articleRef of articlesRef.docs) {
        const article = articleRef.data();
        article.contributor = { ...authUser };

        usersArticles.push({ ...article, uid: articleRef.id });
      }
    }

    this.setState({
      savedArticles,
      usersArticles,
      loading: false
    });
  }

  onLikeClick = articleId => {
    const { authUser } = this.props;
    const { savedArticles } = this.state;

    const article = savedArticles.find(a => a.uid === articleId);

    if (!article.likes || article.likes.length === 0) {
      const newLikes = [authUser.uid];
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.increment.bind(this, article.contributorRef)
      );
      return;
    }

    if (article.likes.includes(authUser.uid)) {
      const newLikes = article.likes.filter(l => l !== authUser.uid);
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.decrement.bind(this, article.contributorRef)
      );
      return;
    }

    const newLikes = [...article.likes, authUser.uid];
    this.updateArticleLikes(
      articleId,
      newLikes,
      changeUserCoins.increment.bind(this, article.contributorRef)
    );
  };

  updateArticleLikes(articleId, likes, doUpdateUserCoins) {
    const { firebase } = this.props;
    const { savedArticles } = this.state;

    firebase
      .article(articleId)
      .set(
        {
          likes,
          likesCount: likes.length
        },
        { merge: true }
      )
      .then(() => {
        const newArticles = [...savedArticles];
        const articleIndex = newArticles.findIndex(a => a.uid === articleId);
        if (articleIndex !== -1) {
          newArticles[articleIndex].likes = [...likes];
        }
        doUpdateUserCoins();
        this.setState({
          savedArticles: newArticles
        });
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  }

  onSaveClick = articleId => {
    const { savedArticles } = this.props.authUser;

    const articles = savedArticles.filter(a => a !== articleId);
    this.updateSavedArticles(articles);
  };

  updateSavedArticles = articles => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const { savedArticles } = this.state;

    const updatedUser = {
      ...authUser,
      savedArticles: [...articles]
    };

    firebase
      .user(authUser.uid)
      .set(
        {
          savedArticles: articles
        },
        { merge: true }
      )
      .then(() => {
        onSetAuthUser(updatedUser);
        const newSavedArticles = savedArticles.filter(a =>
          articles.includes(a.uid)
        );
        this.setState({
          savedArticles: newSavedArticles
        });
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  switchArticleList = (event, value) => {
    this.setState({
      currentArticleList: value
    });
  };

  deleteUserPost = articleId => {
    const { firebase } = this.props;
    const { usersArticles } = this.state;
    const article = usersArticles.find(a => a.uid === articleId);

    if (!article) {
      console.error('There is not article');
      return;
    }

    firebase
      .article(articleId)
      .delete()
      .then(() => {
        this.cleanReferences({ ...article });
        const newUsersArticles = usersArticles.filter(a => a.uid !== articleId);

        this.setState({
          usersArticles: [...newUsersArticles]
        });
      })
      .catch(err => {
        console.error('error: ', err);
        this.setState({
          loading: false
        });
      });
  };

  cleanReferences = article => {
    const { uid, contributorRef, banner, likes, comments } = article;

    if (banner) {
      this.deleteBanner(banner);
    }

    if (likes && likes.length > 0) {
      changeUserCoins.decrement.call(this, contributorRef, likes.length);
    }

    if (comments && Object.keys(comments).length > 0) {
      this.cleanLikesFromComments(comments);
    }

    this.cleanSavedArticles(uid);
  };

  deleteBanner = bannerUrl => {
    this.props.firebase.storage
      .refFromURL(bannerUrl)
      .delete()
      .then()
      .catch(err => {
        console.error('error: ', err);
      });
  };

  cleanLikesFromComments = comments => {
    const commentIds = Object.keys(comments);

    commentIds.forEach(id => {
      const { likes, user } = comments[id];
      if (likes && likes.length > 0) {
        changeUserCoins.decrement.call(this, user.uid, likes.length);
      }
    });
  };

  cleanSavedArticles = articleId => {
    const { firebase } = this.props;

    let query = firebase.users();
    query = query.where('savedArticles', 'array-contains', articleId);

    query
      .get()
      .then(snapshots => {
        snapshots.forEach(doc => {
          const userData = doc.data();
          const userId = doc.id;

          const newSavedArticles = userData.savedArticles.filter(
            id => id !== articleId
          );

          firebase.user(userId).update({
            savedArticles: newSavedArticles
          });
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  render() {
    const { classes, authUser } = this.props;
    const {
      loading,
      savedArticles,
      usersArticles,
      currentArticleList
    } = this.state;

    const {
      firstName,
      lastName,
      username,
      follows,
      followers,
      coins
    } = authUser;
    const coinsCount = coins || 0;
    let sortedSavedArticles = null;
    let sortedUsersArticles = null;

    const avatar = authUser.avatar || DEFAULT_AVATAR;
    const fullName =
      firstName && lastName ? `${firstName} ${lastName}` : username;
    const status = authUser.memberStatus || '';
    const description = authUser.description || '';
    const followsValue = follows ? follows.length : 0;
    const followersValue = followers ? followers.length : 0;
    const userSavedArticlesIds = authUser.savedArticles || [];

    if (savedArticles && savedArticles.length > 1) {
      sortedSavedArticles = authUser.savedArticles.reverse().map(id => {
        return savedArticles.find(a => a.uid === id);
      });
    }

    if (usersArticles && usersArticles.length > 1) {
      sortedUsersArticles = usersArticles.sort((a, b) => b.created - a.created);
    }

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {!loading && (
          <div className={classes.root}>
            <Grid container direction="column">
              <Grid
                className={classes.mainContainer}
                container
                item
                spacing={1}>
                <Grid item xs={12}>
                  <Card className={classes.userCard}>
                    <Grid
                      className={classes.userCardHeader}
                      container
                      wrap="nowrap"
                      spacing={2}>
                      <Grid item>
                        <Avatar
                          alt="Avatar"
                          className={classes.userAvatar}
                          src={avatar}
                        />
                      </Grid>
                      <Grid item className={classes.userInfo}>
                        <Typography variant="h5">{fullName}</Typography>
                        <Typography component="span" variant="body1">
                          {status}
                        </Typography>
                      </Grid>
                    </Grid>
                    {description && (
                      <CardContent className={classes.userDescription}>
                        <Typography>{description}</Typography>
                      </CardContent>
                    )}
                  </Card>
                </Grid>
                <Grid container item spacing={1} wrap="nowrap">
                  <Grid item xs={3}>
                    <Paper className={classes.statistic}>
                      <ThumbUp className={classes.statisticIcon} />
                      <Typography className={classes.likeValue}>
                        {coinsCount} {coinsCount === 1 ? ` LIKE` : ` LIKES`}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs>
                    <Paper
                      className={classes.statistic}
                      component={RouterLink}
                      to={FOLLOWERS}>
                      <People className={classes.statisticIcon} />
                      <Typography className={classes.followValue}>
                        {`${followersValue} Followers`}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs>
                    <Paper
                      className={classes.statistic}
                      component={RouterLink}
                      to={FOLLOWING}>
                      <People className={classes.statisticIcon} />
                      <Typography className={classes.followValue}>
                        {`${followsValue} Following`}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    to={ACCOUNT}
                    component={RouterLink}
                    exact>
                    Settings
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <TabContext value={currentArticleList}>
                    <StyledTabList
                      onChange={this.switchArticleList}
                      variant="fullWidth"
                      aria-label="switch current article list">
                      <StyledTab label="Saved Posts" value="saved" />
                      <StyledTab label="My Posts" value="users" />
                    </StyledTabList>
                    <StyledTabPanel value="saved" className={classes.tabPanel}>
                      {!loading && (
                        <Grid item xs={12}>
                          {!savedArticles ||
                            (savedArticles.length === 0 && (
                              <Typography align="center" variant="body1">
                                You haven&apos;t saved any posts yet
                              </Typography>
                            ))}
                          {savedArticles && savedArticles.length > 0 && (
                            <ArticleList
                              userId={authUser ? authUser.uid : null}
                              articles={sortedSavedArticles || savedArticles}
                              savedArticlesIds={userSavedArticlesIds}
                              onLikeClick={this.onLikeClick}
                              onSaveClick={this.onSaveClick}
                            />
                          )}
                        </Grid>
                      )}
                    </StyledTabPanel>
                    <StyledTabPanel value="users">
                      {!loading && (
                        <Grid item xs={12}>
                          {!usersArticles ||
                            (usersArticles.length === 0 && (
                              <Typography align="center" variant="body1">
                                You don&apos;t have any posts yet
                              </Typography>
                            ))}
                          {usersArticles && usersArticles.length > 0 && (
                            <ArticleList
                              userId={authUser ? authUser.uid : null}
                              articles={sortedUsersArticles || usersArticles}
                              savedArticlesIds={userSavedArticlesIds}
                              isEditable={true}
                              onLikeClick={this.onLikeClick}
                              onSaveClick={this.onSaveClick}
                              onDeleteClick={this.deleteUserPost}
                            />
                          )}
                        </Grid>
                      )}
                    </StyledTabPanel>
                  </TabContext>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser })
});

const condition = authUser => !!authUser;

const ProfileDetails = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(withStyles(styles)(ProfileDetailsBase));

ProfileDetailsBase.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withEmailVerification,
  withAuthorization(condition)
)(ProfilePage);

export { ProfileDetails };
