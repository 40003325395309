export const ACCOUNT = '/account';
export const PROFILE = '/profile';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const ARTICLES = '/articles';
export const ARTICLES_DETAILS = '/articles/:id';
export const AUTHOR = '/author';
export const AUTHOR_DETAILS = '/author/:id';
export const NOTIFICATIONS = '/notifications';
export const NEW_POST = '/new-post';
export const FOLLOWERS = '/followers';
export const FOLLOWING = '/following';
export const TRENDS = '/trends';
export const POPULAR_USERS = '/popular-users';
export const SEARCH = '/search';
export const EXPLORE = '/explore';

export const ARTICLES_FUN = '/articlesfun';
export const ARTICLES_FUN_DETAILS = '/articlesfun/:id';

export const LANDING = '/';
export const PASSWORD_FORGET = '/pw-forget';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SETTINGS = '/settings';
export const VERIFY_EMAIL = '/verify';
export const ONBOARDING = '/onboarding';
