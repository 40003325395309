import React, { Component } from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';

import { AUTHOR } from '../../constants/routes';
import FollowerCard from '../Follow/FollowerCard';

const LOAD_MORE_LIMIT = 6;

class PopularUsersBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      limit: LOAD_MORE_LIMIT,
      loading: true,
      loadingMore: false
    };
  }

  componentDidMount() {
    this.getSortedUsers();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const { limit: prevLimit } = prevState;
    const { limit } = this.state;

    if (limit !== prevLimit) {
      this.getSortedUsers();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { loadingMore } = this.state;
    const isBottom =
      document.documentElement.scrollHeight - window.innerHeight <
      window.scrollY + 20;

    if (!isBottom || loadingMore) return;

    this.onLoadMore();
  };

  onLoadMore = () => {
    this.setState({
      limit: this.state.limit + LOAD_MORE_LIMIT,
      loadingMore: true
    });
  };

  getSortedUsers = () => {
    const { firebase, authUser } = this.props;
    const { limit } = this.state;

    firebase
      .users()
      .orderBy('coins', 'desc')
      .limit(limit)
      .get()
      .then(snapshot => {
        const users = [];

        if (!snapshot.empty) {
          snapshot.forEach(doc => {
            if (authUser && authUser.uid === doc.id) {
              return;
            }

            users.push({
              uid: doc.id,
              ...doc.data()
            });
          });
        }

        this.setState({
          users,
          loading: false,
          loadingMore: false
        });
      })
      .catch(err => {
        console.error('error: ', err);
        this.setState({
          loading: false,
          loadingMore: false
        });
      });
  };

  onFollowClick = (e, userId) => {
    e.preventDefault();

    const { authUser, showSighInDialog } = this.props;

    if (!authUser) {
      showSighInDialog({ pathname: `${AUTHOR}/${userId}` }, 'follow the user');
      return;
    }

    const isFollowed = authUser.follows && authUser.follows.includes(userId);

    if (isFollowed) {
      this.doUnfollow(userId);
      return;
    }

    this.doFollow(userId);
  };

  doUnfollow = userId => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const { users } = this.state;

    const newAuthUserFollows = authUser.follows.filter(id => id !== userId);
    const user = users.find(u => u.uid === userId);
    const newUserFollowers = user.followers.filter(id => id !== authUser.uid);

    firebase
      .user(authUser.uid)
      .set(
        {
          follows: newAuthUserFollows
        },
        { merge: true }
      )
      .then(() => {
        const userIndex = users.findIndex(u => u.uid === userId);
        const updatedUsers = [...users];
        updatedUsers[userIndex] = {
          ...user,
          followers: newUserFollowers
        };
        onSetAuthUser({
          ...authUser,
          follows: newAuthUserFollows
        });
        this.setState({
          users: [...updatedUsers]
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });

    firebase
      .user(userId)
      .set(
        {
          followers: newUserFollowers
        },
        { merge: true }
      )
      .then()
      .catch(err => {
        console.log('error: ', err);
      });
  };

  doFollow = userId => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const { users } = this.state;

    const newAuthUserFollows = authUser.follows
      ? [...authUser.follows, userId]
      : [userId];

    const user = users.find(u => u.uid === userId);
    const newUserFollowers = user.followers
      ? [...user.followers, authUser.uid]
      : [authUser.uid];

    firebase
      .user(authUser.uid)
      .set(
        {
          follows: newAuthUserFollows
        },
        { merge: true }
      )
      .then(() => {
        const userIndex = users.findIndex(u => u.uid === userId);
        const updatedUsers = [...users];
        updatedUsers[userIndex] = {
          ...user,
          followers: newUserFollowers
        };
        onSetAuthUser({
          ...authUser,
          follows: newAuthUserFollows
        });
        this.setState({
          users: [...updatedUsers]
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });

    firebase
      .user(userId)
      .set(
        {
          followers: newUserFollowers
        },
        { merge: true }
      )
      .then()
      .catch(err => {
        console.log('error: ', err);
      });
  };

  render() {
    const { classes, authUser } = this.props;
    const { users, loading, loadingMore } = this.state;
    return (
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item className={classes.headerGrid}>
            <div className={classes.header}>
              <Typography className={classes.pageTitle} variant="h1">
                Discover people to follow
              </Typography>
              <Typography className={classes.pageSubtitle}>
                You might like to follow these popular users and finance
                experts.
              </Typography>
            </div>
          </Grid>
          {!loading && users.length === 0 && (
            <Grid item>
              <Typography align="center" variant="body1">
                There are not any users
              </Typography>
            </Grid>
          )}
          {!loading && users.length > 0 && (
            <Grid className={classes.usersGrid} item container>
              {users.map(
                ({
                  uid,
                  avatar,
                  firstName,
                  lastName,
                  username,
                  memberStatus,
                  occupation
                }) => {
                  const fullName =
                    firstName && lastName
                      ? `${firstName} ${lastName}`
                      : username;

                  let status = memberStatus || '';
                  if (occupation) {
                    status =
                      status !== ''
                        ? `${status} | ${occupation}`
                        : `${occupation}`;
                  }

                  const isFollowed = authUser
                    ? authUser.follows && authUser.follows.includes(uid)
                    : false;

                  return (
                    <Grid key={uid} item lg={4} md={12} xs={12}>
                      <FollowerCard
                        userId={uid}
                        avatar={avatar}
                        fullName={fullName}
                        status={status}
                        isFollowed={isFollowed}
                        onFollowClick={e => this.onFollowClick(e, uid)}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
          )}

          {(loading || loadingMore) && (
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default PopularUsersBase;
