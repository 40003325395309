import React, { Component } from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';

import { ARTICLES } from '../../constants/routes';
import { changeUserCoins } from '../../common/Helpers';
import ArticleList from '../Articles/ArticleList';
import { ArticleTypeFilterCard } from '../common';

const LOAD_MORE_LIMIT = 6;

const getArticles = async ({ firebase, limit, articlesType, success }) => {
  const articles = [];
  const contributors = {};
  let query = firebase.articles().where('status', '==', 'published');

  if (articlesType !== 'all') {
    query = query.where('type', '==', `${articlesType}`);
  }

  const snapshot = await query
    .orderBy('likesCount', 'desc')
    .orderBy('created', 'desc')
    .limit(limit)
    .get();

  for (const doc of snapshot.docs) {
    const article = doc.data();
    let contributor = contributors[article.contributorRef];

    if (!contributor) {
      const contributorRef = await firebase.user(article.contributorRef).get();
      contributor = contributorRef.data();
      contributors[article.contributorRef] = { ...contributor };
    }

    articles.push({
      uid: doc.id,
      contributor: { ...contributor },
      ...article
    });
  }

  success(articles);
};

class TrendsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      limit: LOAD_MORE_LIMIT,
      filter: 'all',
      loading: true,
      loadingMore: false
    };
  }

  componentDidMount() {
    const { firebase } = this.props;
    const { limit, filter } = this.state;

    const requestOptions = {
      firebase,
      limit,
      articlesType: filter,
      success: this.handleSuccessGettingArticles
    };

    getArticles(requestOptions);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const { firebase } = this.props;
    const { limit: prevLimit, filter: prevFilter } = prevState;
    const { limit, filter } = this.state;

    if (prevLimit !== limit || prevFilter !== filter) {
      const requestOptions = {
        firebase,
        limit,
        articlesType: filter,
        success: this.handleSuccessGettingArticles
      };

      getArticles(requestOptions);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { loadingMore } = this.state;
    const isBottom =
      document.documentElement.scrollHeight - window.innerHeight <
      window.scrollY + 20;

    if (!isBottom || loadingMore) return;

    this.onLoadMore();
  };

  handleSuccessGettingArticles = articles => {
    this.setState({
      articles,
      loading: false,
      loadingMore: false
    });
  };

  handleFilterChange = event => {
    this.setState({
      filter: event.target.value,
      limit: LOAD_MORE_LIMIT,
      loading: true
    });
  };

  onLoadMore = () => {
    this.setState({
      limit: this.state.limit + LOAD_MORE_LIMIT,
      loadingMore: true
    });
  };

  onLikeClick = articleId => {
    const { authUser } = this.props;
    const { articles } = this.state;
    const article = articles.find(a => a.uid === articleId);

    if (!authUser) {
      this.props.showSighInDialog(
        { pathname: `${ARTICLES}/${article.uid}` },
        'like the post'
      );
      return;
    }

    if (!article.likes || article.likes.length === 0) {
      const newLikes = [authUser.uid];
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.increment.bind(this, article.contributorRef)
      );
      return;
    }

    if (article.likes.includes(authUser.uid)) {
      const newLikes = article.likes.filter(l => l !== authUser.uid);
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.decrement.bind(this, article.contributorRef)
      );
      return;
    }

    const newLikes = [...article.likes, authUser.uid];
    this.updateArticleLikes(
      articleId,
      newLikes,
      changeUserCoins.increment.bind(this, article.contributorRef)
    );
  };

  updateArticleLikes(articleId, likes, doUpdateUserCoins) {
    const { firebase } = this.props;
    const { articles } = this.state;

    firebase
      .article(articleId)
      .set(
        {
          likes,
          likesCount: likes.length
        },
        { merge: true }
      )
      .then(() => {
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === articleId);
        if (articleIndex !== -1) {
          newArticles[articleIndex].likes = [...likes];
        }
        doUpdateUserCoins();
        this.setState({
          articles: [...newArticles]
        });
      })
      .catch(error => {
        console.log('error :', error);
      });
  }

  onSaveClick = articleId => {
    const { authUser, showSighInDialog } = this.props;

    if (!authUser) {
      showSighInDialog(
        { pathname: `${ARTICLES}/${articleId}` },
        'save the post'
      );
      return;
    }

    if (!authUser.savedArticles) {
      this.updateSavedArticles([articleId]);
      return;
    }

    const isSaved = authUser.savedArticles.includes(articleId);
    if (isSaved) {
      const articles = authUser.savedArticles.filter(a => a !== articleId);
      this.updateSavedArticles(articles);
      return;
    }

    const articles = [...authUser.savedArticles, articleId];
    this.updateSavedArticles(articles);
  };

  updateSavedArticles = articles => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const updatedUser = {
      ...authUser,
      savedArticles: [...articles]
    };

    firebase
      .user(authUser.uid)
      .set(
        {
          savedArticles: articles
        },
        { merge: true }
      )
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  render() {
    const { classes, authUser } = this.props;
    const { articles, filter, loading, loadingMore } = this.state;

    const userSavedArticlesIds = (authUser && authUser.savedArticles) || [];

    return (
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item className={classes.headerGrid}>
            <div className={classes.header}>
              <Typography className={classes.pageTitle} variant="h1">
                Discover the hottest content
              </Typography>
              <Typography className={classes.pageSubtitle}>
                The most liked and talked about content on Zonotho right now
              </Typography>
            </div>
          </Grid>
          <Grid item className={classes.filterGrid}>
            <ArticleTypeFilterCard
              value={filter}
              isDisabled={loading}
              onChange={this.handleFilterChange}
            />
          </Grid>
          <Grid item className={classes.articlesGrid}>
            <>
              {!loading && articles.length === 0 && (
                <Typography align="center" variant="body1">
                  There are not any posts
                </Typography>
              )}
              {!loading && articles.length > 0 && (
                <ArticleList
                  userId={authUser ? authUser.uid : null}
                  articles={articles}
                  savedArticlesIds={userSavedArticlesIds}
                  onLikeClick={this.onLikeClick}
                  onSaveClick={this.onSaveClick}
                />
              )}
            </>
          </Grid>

          {(loading || loadingMore) && (
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          )}
        </Grid>
      </div>
    );
  }
}

export default TrendsBase;
