import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
  Card,
  LinearProgress,
  Grid,
  Avatar,
  Typography,
  CardContent,
  Paper,
  Divider
} from '@material-ui/core';
import {
  allAuthorDataSelector,
  authorIsFollowedSelector,
  authorArticlesSelector
} from './selector';
import { withFirebase } from '../Firebase';
import { getIdFromUrl, changeUserCoins } from '../../common/Helpers';
import { AUTHOR, ARTICLES } from '../../constants/routes';
import ArticleList from '../Articles/ArticleList';

import { People, ThumbUp } from '@material-ui/icons';

import {
  styles,
  StyledButton,
  StyledTabList,
  StyledTab,
  StyledTabPanel
} from './Author.style';
import { TabContext } from '@material-ui/lab';

const INITIAL_STATE = {
  loading: true,
  currentArticleList: 'recent'
};
const DEFAULT_AVATAR = '/images/default-avatar.png';

class AuthorPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const {
      authUser,
      location,
      firebase,
      onSetAuthor,
      onSetArticles,
      onSetIsFollowed
    } = this.props;

    const authorId = getIdFromUrl(location.pathname, 'author');

    if (!authorId) {
      this.setState({
        loading: false
      });

      return;
    }

    this.unsubscribe = async function unsubscribe() {
      const authorRef = await firebase.user(authorId).get();
      const newAuthor = {
        ...authorRef.data(),
        uid: authorId
      };
      onSetAuthor(newAuthor);

      if (authUser && authUser.follows && authUser.follows.includes(authorId)) {
        onSetIsFollowed(true);
      }

      let query = firebase.articles();
      query = query.where('status', '==', 'published');
      query = query.where('contributorRef', '==', `${authorId}`);
      const articlesRef = await query.orderBy('created', 'desc').get();

      if (articlesRef.docs.length > 0) {
        const articles = [];

        for (const articleRef of articlesRef.docs) {
          const article = articleRef.data();
          articles.push({
            ...article,
            uid: articleRef.id,
            contributor: { ...newAuthor }
          });
        }

        onSetArticles(articles);
      }

      this.setState({
        loading: false
      });
    };

    try {
      this.unsubscribe();
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  componentDidUpdate() {
    const { authUser, author, isFollowed, onSetIsFollowed } = this.props;

    if (authUser && author) {
      if (
        authUser.follows &&
        authUser.follows.includes(author.uid) &&
        !isFollowed
      ) {
        onSetIsFollowed(true);
        return;
      }

      if (
        authUser.follows &&
        !authUser.follows.includes(author.uid) &&
        isFollowed
      ) {
        onSetIsFollowed(false);
        return;
      }
    }
  }

  componentWillUnmount() {
    const { onSetAuthor, onSetIsFollowed, onSetArticles } = this.props;
    onSetAuthor(null);
    onSetIsFollowed(false);
    onSetArticles(null);
  }

  onFollowClick = () => {
    const { author, authUser, isFollowed, showSighInDialog } = this.props;

    if (!authUser) {
      showSighInDialog(
        { pathname: `${AUTHOR}/${author.uid}` },
        'follow the user'
      );
      return;
    }

    if (isFollowed) {
      this.doUnfollows(authUser, author);
      return;
    }

    this.doFollows(authUser, author);
  };

  doFollows = (user, author) => {
    const updatedUser = user.follows
      ? { ...user, follows: [...user.follows, author.uid] }
      : { ...user, follows: [author.uid] };

    const updatedAuthor = author.followers
      ? { ...author, followers: [...author.followers, user.uid] }
      : { ...author, followers: [user.uid] };

    this.updateFollows(updatedUser, updatedAuthor);
  };

  doUnfollows = (user, author) => {
    const newUserFollows = user.follows.filter(id => id !== author.uid);
    const newAuthorFollowers = author.followers.filter(id => id !== user.uid);

    const updatedUser = { ...user, follows: [...newUserFollows] };
    const updatedAuthor = { ...author, followers: [...newAuthorFollowers] };

    this.updateFollows(updatedUser, updatedAuthor);
  };

  updateFollows = (updatedUser, updatedAuthor) => {
    const { firebase, onSetAuthUser, onSetAuthor } = this.props;

    firebase
      .user(updatedUser.uid)
      .set(
        {
          follows: updatedUser.follows
        },
        { merge: true }
      )
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });

    firebase
      .user(updatedAuthor.uid)
      .set(
        {
          followers: updatedAuthor.followers
        },
        { merge: true }
      )
      .then(() => {
        onSetAuthor(updatedAuthor);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  onLikeClick = articleId => {
    const { author, authUser, articles, showSighInDialog } = this.props;
    const article = articles.find(a => a.uid === articleId);

    if (!authUser) {
      showSighInDialog(
        { pathname: `${ARTICLES}/${article.uid}` },
        'like the post'
      );
      return;
    }

    if (!article.likes || article.likes.length === 0) {
      const newLikes = [authUser.uid];
      let authorCoins = author.coins || 0;
      authorCoins += 1;
      this.updateArticleLikes(
        articleId,
        newLikes,
        authorCoins,
        changeUserCoins.increment.bind(this, article.contributorRef)
      );
      return;
    }

    if (article.likes.includes(authUser.uid)) {
      const newLikes = article.likes.filter(l => l !== authUser.uid);
      const authorCoins = author.coins || 0;
      const newAuthorCoins = authorCoins !== 0 ? authorCoins - 1 : authorCoins;
      this.updateArticleLikes(
        articleId,
        newLikes,
        newAuthorCoins,
        changeUserCoins.decrement.bind(this, article.contributorRef)
      );
      return;
    }

    const newLikes = [...article.likes, authUser.uid];
    let authorCoins = author.coins || 0;
    authorCoins += 1;
    this.updateArticleLikes(
      articleId,
      newLikes,
      authorCoins,
      changeUserCoins.increment.bind(this, article.contributorRef)
    );
  };

  updateArticleLikes = (articleId, likes, coins, doUpdateUserCoins) => {
    const {
      author,
      articles,
      firebase,
      onSetArticles,
      onSetAuthor
    } = this.props;

    firebase
      .article(articleId)
      .set(
        {
          likes,
          likesCount: likes.length
        },
        { merge: true }
      )
      .then(() => {
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === articleId);
        if (articleIndex !== -1) {
          newArticles[articleIndex].likes = [...likes];
        }
        doUpdateUserCoins();
        onSetAuthor({
          ...author,
          coins
        });
        onSetArticles(newArticles);
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  };

  onSaveClick = articleId => {
    const { authUser, showSighInDialog } = this.props;

    if (!authUser) {
      showSighInDialog(
        { pathname: `${ARTICLES}/${articleId}` },
        'save the post'
      );
      return;
    }

    if (!authUser.savedArticles) {
      this.updateSavedArticles([articleId]);
      return;
    }

    const isSaved = authUser.savedArticles.includes(articleId);
    if (isSaved) {
      const articles = authUser.savedArticles.filter(a => a !== articleId);
      this.updateSavedArticles(articles);
      return;
    }

    const articles = [...authUser.savedArticles, articleId];
    this.updateSavedArticles(articles);
  };

  updateSavedArticles = articles => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const updatedUser = {
      ...authUser,
      savedArticles: [...articles]
    };

    firebase
      .user(authUser.uid)
      .set(
        {
          savedArticles: articles
        },
        { merge: true }
      )
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  switchArticleList = (event, value) => {
    this.setState({
      currentArticleList: value
    });
  };

  render() {
    const { loading, currentArticleList } = this.state;
    const { authUser, classes, author, articles, isFollowed } = this.props;
    let avatar, fullName, status, followsValue, followersValue;
    let authorCoins = 0;
    const savedArticlesIds =
      authUser && authUser.savedArticles ? authUser.savedArticles : [];
    let topSortedArticles = articles ? [...articles] : [];

    if (articles && articles.length > 1) {
      const notSorted = [...articles];
      topSortedArticles = notSorted.sort((a, b) => {
        const likesCountA = a.likes ? a.likes.length : 0;
        const likesCountB = b.likes ? b.likes.length : 0;

        return likesCountB - likesCountA;
      });
    }

    if (author) {
      const {
        memberStatus,
        firstName,
        lastName,
        username,
        occupation,
        follows,
        followers,
        coins
      } = author;

      avatar = author.avatar || DEFAULT_AVATAR;
      fullName = firstName ? `${firstName} ${lastName}` : username;

      status = memberStatus || '';
      if (occupation) {
        status = status !== '' ? `${status} | ${occupation}` : `${occupation}`;
      }

      followsValue = follows ? follows.length : 0;
      followersValue = followers ? followers.length : 0;

      if (coins) {
        authorCoins = coins;
      }
    }

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {!loading && !author && (
          <div>
            <Grid item lg={12} md={12} xs={12} className={classes.content}>
              <Typography align="center" gutterBottom variant="body1">
                There is no author available
              </Typography>
            </Grid>
          </div>
        )}
        {!loading && (
          <div className={classes.root}>
            <Grid container direction="column">
              <Grid
                className={classes.mainContainer}
                container
                item
                spacing={1}>
                <Grid item xs={12}>
                  <Card className={classes.userCard}>
                    <Grid
                      className={classes.userCardHeader}
                      container
                      wrap="nowrap"
                      spacing={2}>
                      <Grid item>
                        <Avatar
                          alt="Avatar"
                          className={classes.userAvatar}
                          src={avatar}
                        />
                      </Grid>
                      <Grid item className={classes.userInfo}>
                        <Typography variant="h5">{fullName}</Typography>
                        <Typography component="span" variant="body1">
                          {status}
                        </Typography>
                      </Grid>
                    </Grid>
                    {author.description && (
                      <CardContent className={classes.userDescription}>
                        <Typography>{author.description}</Typography>
                      </CardContent>
                    )}
                  </Card>
                </Grid>
                <Grid container item spacing={1} wrap="nowrap">
                  <Grid item xs={3}>
                    <Paper className={classes.statistic}>
                      <ThumbUp className={classes.statisticIcon} />
                      <Typography className={classes.likeValue}>
                        {authorCoins} {authorCoins === 1 ? ` LIKE` : ` LIKES`}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs>
                    <Paper className={classes.statistic}>
                      <People className={classes.statisticIcon} />
                      <Typography className={classes.followValue}>
                        {`${followersValue} Followers`}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs>
                    <Paper className={classes.statistic}>
                      <People className={classes.statisticIcon} />
                      <Typography className={classes.followValue}>
                        {`${followsValue} Following`}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <StyledButton
                    className={clsx({
                      followBtn: true,
                      following: isFollowed
                    })}
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={this.onFollowClick}>
                    {isFollowed ? 'Following' : 'Follow'}
                  </StyledButton>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <TabContext value={currentArticleList}>
                    <StyledTabList
                      onChange={this.switchArticleList}
                      variant="fullWidth"
                      aria-label="switch current article list">
                      <StyledTab label="Recent Posts" value="recent" />
                      <StyledTab label="Top Posts" value="top" />
                    </StyledTabList>
                    <StyledTabPanel className={classes.tabPanel} value="recent">
                      {!loading && (
                        <Grid item xs={12}>
                          {!articles ||
                            (articles.length === 0 && (
                              <Typography align="center" variant="body1">
                                This user doesn&apos;t have any posts yet
                              </Typography>
                            ))}
                          {articles && articles.length > 0 && (
                            <ArticleList
                              userId={authUser ? authUser.uid : null}
                              articles={articles}
                              savedArticlesIds={savedArticlesIds}
                              onLikeClick={this.onLikeClick}
                              onSaveClick={this.onSaveClick}
                            />
                          )}
                        </Grid>
                      )}
                    </StyledTabPanel>
                    <StyledTabPanel className={classes.tabPanel} value="top">
                      {!loading && (
                        <Grid item xs={12}>
                          {!articles ||
                            (articles.length === 0 && (
                              <Typography align="center" variant="body1">
                                This user doesn&apos;t have any posts yet
                              </Typography>
                            ))}
                          {articles && articles.length > 0 && (
                            <ArticleList
                              userId={authUser ? authUser.uid : null}
                              articles={topSortedArticles}
                              savedArticlesIds={savedArticlesIds}
                              onLikeClick={this.onLikeClick}
                              onSaveClick={this.onSaveClick}
                            />
                          )}
                        </Grid>
                      )}
                    </StyledTabPanel>
                  </TabContext>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

AuthorPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  author: allAuthorDataSelector(state),
  articles: authorArticlesSelector(state),
  isFollowed: authorIsFollowedSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
  onSetAuthor: author => dispatch({ type: 'AUTHOR_SET', author }),
  onSetArticles: articles =>
    dispatch({ type: 'AUTHOR_ARTICLES_SET', articles }),
  onSetIsFollowed: isFollowed =>
    dispatch({ type: 'AUTHOR_IS_FOLLOWED_SET', isFollowed }),
  showAlert: alert => dispatch({ type: 'ALERT_SET', alert }),
  showSighInDialog: (locationState, message) =>
    dispatch({ type: 'SIGN_IN_DIALOG_VISIBLE', locationState, message })
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(AuthorPage));
