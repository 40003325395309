/* eslint-disable no-console */
/* eslint-disable no-invalid-this */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import ReactPlayer from 'react-player/youtube';
import Moment from 'react-moment';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import uniqid from 'uniqid';

import { withStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography,
  Grid,
  Button,
  LinearProgress
} from '@material-ui/core';

import { AUTHOR, ARTICLES, NEW_POST } from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { Contributor, Media, Actions, Comments } from './components';
import { allArticlesSelector } from '../Articles/selectors';
import {
  getIdFromUrl,
  changeUserCoins,
  getStrippedString
} from '../../common/Helpers';

import { styles } from './Article.style';

class ArticlePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      article: null,
      isDeleted: false
    };
  }

  componentDidMount() {
    const { articles, location, firebase } = this.props;
    const articleId = getIdFromUrl(location.pathname, 'articles');

    if (!articleId) {
      this.setState({
        loading: false,
        article: null
      });

      return;
    }

    window.scrollTo(0, 0);

    if (articles && articles.length > 0) {
      const article = articles.find(a => a.uid === articleId);

      if (article) {
        this.setState({
          loading: false,
          article: { ...article }
        });

        return;
      }
    }

    this.unsubscribe = async function unsubscribe() {
      const articleRef = await firebase.article(articleId).get();

      if (!articleRef || !articleRef.data()) {
        this.setState({
          loading: false
        });

        return;
      }

      const article = articleRef.data();
      const userRef = await firebase.user(article.contributorRef).get();
      article.contributor = userRef.data();

      article.uid = articleId;

      this.setState({
        loading: false,
        article: { ...article }
      });
    };

    try {
      this.unsubscribe();
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  nonSignInHandler = message => {
    this.props.showSighInDialog(
      { pathname: `${ARTICLES}/${this.state.article.uid}` },
      message
    );
  };

  onArticleLikeClick = () => {
    const { article } = this.state;
    const { authUser } = this.props;

    if (!authUser) {
      this.nonSignInHandler('like the post');
      return;
    }

    if (!article.likes || article.likes.length === 0) {
      const newLikes = [authUser.uid];
      this.updateArticleLikes(
        article.uid,
        newLikes,
        changeUserCoins.increment.bind(this, article.contributorRef)
      );
      return;
    }

    if (article.likes.includes(authUser.uid)) {
      const newLikes = article.likes.filter(l => l !== authUser.uid);
      this.updateArticleLikes(
        article.uid,
        newLikes,
        changeUserCoins.decrement.bind(this, article.contributorRef)
      );
      return;
    }

    const newLikes = [...article.likes, authUser.uid];
    this.updateArticleLikes(
      article.uid,
      newLikes,
      changeUserCoins.increment.bind(this, article.contributorRef)
    );
  };

  updateArticleLikes(articleId, likes, doUpdateUserCoins) {
    const { article } = this.state;
    const { firebase } = this.props;

    firebase
      .article(articleId)
      .set(
        {
          likes,
          likesCount: likes.length
        },
        { merge: true }
      )
      .then(() => {
        const newArticle = {
          ...article,
          likes: [...likes]
        };
        doUpdateUserCoins();
        this.setState({
          article: newArticle
        });
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  }

  onSaveClick = () => {
    const { article } = this.state;
    const { authUser } = this.props;

    if (!authUser) {
      this.nonSignInHandler('save the post');
      return;
    }

    if (!authUser.savedArticles) {
      this.updateSavedArticles([article.uid]);
      return;
    }

    const isSaved = authUser.savedArticles.includes(article.uid);
    if (isSaved) {
      const articles = authUser.savedArticles.filter(a => a !== article.uid);
      this.updateSavedArticles(articles);
      return;
    }

    const articles = [...authUser.savedArticles, article.uid];
    this.updateSavedArticles(articles);
  };

  updateSavedArticles = articles => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const updatedUser = {
      ...authUser,
      savedArticles: [...articles]
    };

    firebase
      .user(authUser.uid)
      .set(
        {
          savedArticles: articles
        },
        { merge: true }
      )
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  onFollowClick = e => {
    e.stopPropagation();
    const { authUser } = this.props;
    const { article } = this.state;

    if (!authUser) {
      this.nonSignInHandler('follow the user');
      return;
    }

    const newFollows = authUser.follows
      ? [...authUser.follows, article.contributorRef]
      : [article.contributorRef];

    const newFollowers = article.contributor.followers
      ? [...article.contributor.followers, authUser.uid]
      : [authUser.uid];

    this.doFollow(newFollows, newFollowers);
  };

  doFollow = (follows, followers) => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const { article } = this.state;

    firebase
      .user(authUser.uid)
      .set(
        {
          follows
        },
        { merge: true }
      )
      .then(() => {
        const updatedUser = { ...authUser, follows };
        onSetAuthUser(updatedUser);
      })
      .catch(err => {
        console.error('error: ', err);
      });

    firebase
      .user(article.contributorRef)
      .set(
        {
          followers
        },
        { merge: true }
      )
      .then(() => {
        const updatedContributor = {
          ...article.contributor,
          followers
        };
        const updatedArticle = {
          ...article,
          contributor: updatedContributor
        };

        this.setState({
          article: updatedArticle
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  onAuthorClick = () => {
    const { history, onSetSelectedAuthor } = this.props;
    const { article } = this.state;
    const author = {
      ...article.contributor,
      uid: article.contributorRef
    };

    onSetSelectedAuthor(author);
    history.push(`${AUTHOR}/${article.contributorRef}`);
  };

  makeNotification = type => {
    const MAX_CHARACTERS = 30;
    const { authUser, firebase } = this.props;
    const { article } = this.state;
    const { avatar, firstName, lastName, username } = authUser;
    const title = article.title || article.content || article.description;

    const notification = {
      type,
      id: uniqid(),
      created: firebase.timeStamp.fromDate(new Date()),
      user: {
        avatar: avatar || '',
        uid: authUser.uid,
        fullName: firstName && lastName ? `${firstName} ${lastName}` : username
      },
      post: {
        uid: article.uid,
        title: getStrippedString(title, MAX_CHARACTERS)
      },
      hasRead: false
    };

    this.sendNotification(notification);
  };

  collectUsersToNotification = async () => {
    const { authUser, firebase } = this.props;
    const { article } = this.state;
    const collectedUsers = [];
    const isAuthor = authUser.uid === article.contributorRef;

    if (!isAuthor) {
      collectedUsers.push(article.contributorRef);
    }

    let query = firebase.users();
    query = query.where('savedArticles', 'array-contains', article.uid);
    const savedArticleUsersRef = await query.get();

    if (savedArticleUsersRef.docs.length > 0) {
      for (const userRef of savedArticleUsersRef.docs) {
        if (userRef.id !== authUser.uid) {
          collectedUsers.push(userRef.id);
        }
      }
    }

    if (article.likes && article.likes.length > 0) {
      article.likes.forEach(userId => {
        const isExist = collectedUsers.includes(userId);
        if (!isExist && userId !== authUser.uid) {
          collectedUsers.push(userId);
        }
      });
    }

    const commentKeys = article.comments ? Object.keys(article.comments) : null;

    if (commentKeys && commentKeys.length > 0) {
      commentKeys.forEach(commentId => {
        const userId = article.comments[commentId].user.uid;
        const isExist = collectedUsers.includes(userId);
        if (!isExist && userId !== authUser.uid) {
          collectedUsers.push(userId);
        }
      });
    }

    return collectedUsers;
  };

  sendNotification = async notification => {
    const { firebase } = this.props;
    const users = await this.collectUsersToNotification();

    const { id: key, ...value } = notification;

    users.forEach(userId => {
      firebase
        .user(userId)
        .get()
        .then(userRef => {
          const user = userRef.data();
          const notifications = user.notifications || {};
          const newNotifications = {
            ...notifications,
            [key]: value
          };

          firebase.user(userId).set(
            {
              notifications: newNotifications
            },
            { merge: true }
          );
        })
        .catch(err => {
          console.error('error: ', err);
        });
    });
  };

  makeNewComment = comment => {
    const { authUser, firebase } = this.props;
    const { article } = this.state;
    const id = uniqid();
    const isNewComment = true;

    const newComment = {
      content: comment,
      user: {
        uid: authUser.uid,
        avatar: authUser.avatar || '',
        username: authUser.username || '',
        firstName: authUser.firstName || '',
        lastName: authUser.lastName || ''
      },
      likes: [],
      date: firebase.timeStamp.fromDate(new Date())
    };
    const comments = article.comments ? { ...article.comments } : {};
    comments[id] = { ...newComment };

    this.updateComments(comments, isNewComment);
  };

  editComment = (commentId, editedComment) => {
    const { article } = this.state;
    const comments = { ...article.comments };
    comments[commentId].content = editedComment;
    comments[commentId].isEdited = true;

    this.updateComments(comments);
  };

  deleteComment = commentId => {
    const { article } = this.state;
    const comments = { ...article.comments };
    const isDeleted = delete comments[commentId];

    if (isDeleted) {
      this.updateComments(comments);
    }
  };

  updateComments = (comments, isNewComment = false) => {
    const { firebase } = this.props;
    const { article } = this.state;

    firebase
      .article(article.uid)
      .update({
        comments: { ...comments }
      })
      .then(() => {
        if (isNewComment) {
          this.makeNotification('response');
        }
        this.setState({
          article: {
            ...article,
            comments
          }
        });
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  };

  onCommentLikeClick = commentId => {
    const { article } = this.state;
    const { authUser } = this.props;

    if (!authUser) {
      this.nonSignInHandler('like the response');
      return;
    }

    if (authUser.uid === article.comments[commentId].user.uid) {
      return;
    }

    const comment = { ...article.comments[commentId] };

    if (!comment.likes || comment.likes.length === 0) {
      comment.likes = [authUser.uid];
      this.updateArticleComments(
        commentId,
        comment,
        changeUserCoins.increment.bind(this, comment.user.uid)
      );

      return;
    }

    if (comment.likes.includes(authUser.uid)) {
      const newLikes = comment.likes.filter(l => l !== authUser.uid);
      comment.likes = [...newLikes];
      this.updateArticleComments(
        commentId,
        comment,
        changeUserCoins.decrement.bind(this, comment.user.uid)
      );

      return;
    }

    comment.likes = [...comment.likes, authUser.uid];
    this.updateArticleComments(
      commentId,
      comment,
      changeUserCoins.increment.bind(this, comment.user.uid)
    );
  };

  updateArticleComments = (commentId, comment, doUpdateUserCoins) => {
    const { firebase } = this.props;
    const { article } = this.state;
    const comments = {
      ...article.comments,
      [commentId]: { ...comment }
    };

    firebase
      .article(article.uid)
      .set(
        {
          comments
        },
        { merge: true }
      )
      .then(() => {
        const newArticle = {
          ...article,
          comments
        };
        doUpdateUserCoins();
        this.setState({
          article: newArticle
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  deleteArticle = () => {
    const { firebase } = this.props;
    const { article } = this.state;

    this.setState({
      loading: true
    });

    firebase
      .article(article.uid)
      .delete()
      .then(() => {
        this.cleanReferences({ ...article });
        this.setState({
          loading: false,
          article: null,
          isDeleted: true
        });
      })
      .catch(err => {
        console.error('error: ', err);
        this.setState({
          loading: false
        });
      });
  };

  cleanReferences = article => {
    const { uid, contributorRef, banner, likes, comments } = article;

    if (banner) {
      this.deleteBanner(banner);
    }

    if (likes && likes.length > 0) {
      changeUserCoins.decrement.call(this, contributorRef, likes.length);
    }

    if (comments && Object.keys(comments).length > 0) {
      this.cleanLikesFromComments(comments);
    }

    this.cleanSavedArticles(uid);
  };

  deleteBanner = bannerUrl => {
    this.props.firebase.storage
      .refFromURL(bannerUrl)
      .delete()
      .then()
      .catch(err => {
        console.error('error: ', err);
      });
  };

  cleanLikesFromComments = comments => {
    const commentIds = Object.keys(comments);

    commentIds.forEach(id => {
      const { likes, user } = comments[id];
      if (likes && likes.length > 0) {
        changeUserCoins.decrement.call(this, user.uid, likes.length);
      }
    });
  };

  cleanSavedArticles = articleId => {
    const { firebase } = this.props;

    let query = firebase.users();
    query = query.where('savedArticles', 'array-contains', articleId);

    query
      .get()
      .then(snapshots => {
        snapshots.forEach(doc => {
          const userData = doc.data();
          const userId = doc.id;

          const newSavedArticles = userData.savedArticles.filter(
            id => id !== articleId
          );

          firebase.user(userId).update({
            savedArticles: newSavedArticles
          });
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  render() {
    const { classes, authUser, firebase } = this.props;
    const { loading, article, isDeleted } = this.state;
    const tags = [];
    const comments = [];
    let avatar, fullName, likes, status, readingTime;
    let isLiked = false;
    let isSaved = false;
    let isFollowed = false;
    let isTimestamp = false;
    const shouldHideActionsBlock =
      article &&
      article.type &&
      (article.type === 'question' || article.type === 'thought');
    const authUserIsArticleAuthor =
      authUser && article && authUser.uid === article.contributorRef;

    if (article) {
      avatar = article.contributor.avatar
        ? article.contributor.avatar
        : '/images/default-avatar.png';

      fullName = article.contributor.firstName
        ? `${article.contributor.firstName} ${article.contributor.lastName}`
        : article.contributor.username;

      likes = article.likes ? article.likes.length : 0;

      status = article.contributor.memberStatus || '';
      if (article.contributor.occupation) {
        status =
          status !== ''
            ? `${status} | ${article.contributor.occupation}`
            : `${article.contributor.occupation}`;
      }

      if (article.tags && article.tags.length > 0) {
        const maxTags = 5;
        article.tags.slice(0, maxTags).forEach(tag => {
          tags.push(
            tag.charAt(0).toUpperCase() + tag.substring(1).toLowerCase()
          );
        });
      }

      if (authUser) {
        const savedArticles = authUser.savedArticles || [];
        const articleLikes = article.likes || [];
        const follows = authUser.follows || [];

        isSaved = savedArticles.includes(article.uid);
        isLiked = articleLikes.includes(authUser.uid);
        isFollowed = follows.includes(article.contributorRef);
      }

      readingTime = article.readingTime ? `${article.readingTime} min` : null;
      isTimestamp = article.created instanceof firebase.timeStamp;

      if (article.comments) {
        const data = Object.keys(article.comments);
        data.forEach(key => {
          comments.push({
            uid: key,
            ...article.comments[key]
          });
        });
      }
    }

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}

        <div className={classes.root}>
          {!loading && !article && !isDeleted && (
            <div>
              <Grid item lg={12} md={12} xs={12} className={classes.content}>
                <Typography align="center" gutterBottom variant="body1">
                  There are no article available
                </Typography>
              </Grid>
            </div>
          )}
          {!loading && !article && isDeleted && (
            <Card className={classes.successMessage}>
              <CardContent className={classes.successMessageContent}>
                <Typography className={classes.successMessageTitle}>
                  Post deleted!
                </Typography>
              </CardContent>
              <CardActions className={classes.successMessageActions}>
                <Button
                  className={classes.addPostBtn}
                  variant="contained"
                  component={RouterLink}
                  to={`${NEW_POST}`}>
                  Add new
                </Button>
              </CardActions>
            </Card>
          )}
          {!loading && article && (
            <div className={classes.pageContent}>
              <Card>
                <CardActionArea
                  className={classes.contributorContainer}
                  component="div"
                  disableRipple={authUserIsArticleAuthor}
                  onClick={authUserIsArticleAuthor ? null : this.onAuthorClick}>
                  {article.contributor && (
                    <Contributor
                      avatar={avatar}
                      fullName={fullName}
                      status={status}
                      isAuthor={authUserIsArticleAuthor}
                      isFollowed={isFollowed}
                      onFollowClick={this.onFollowClick}
                      onDeleteClick={this.deleteArticle}
                    />
                  )}
                </CardActionArea>
                {article.type === 'video' && (
                  <CardMedia className={classes.videoContainer}>
                    <ReactPlayer
                      className={classes.videoPlayer}
                      url={article.content}
                      controls
                      width="320"
                      height="240"
                    />
                  </CardMedia>
                )}
                {article.banner && (
                  <Media
                    imgSrc={article.banner}
                    readingTime={readingTime}
                    tags={tags}
                  />
                )}
                {!shouldHideActionsBlock && (
                  <Actions
                    likeValue={likes}
                    commentValue={comments.length}
                    isLiked={isLiked}
                    isSaved={isSaved}
                    isAuthor={authUserIsArticleAuthor}
                    onLikeClick={this.onArticleLikeClick}
                    onSaveClick={this.onSaveClick}
                    article={article}
                  />
                )}
                <CardContent className={classes.articleContentContainer}>
                  <Grid container key={article.uid} direction="column">
                    {isTimestamp && (
                      <Grid item>
                        <Moment className={classes.postingTime} fromNow>
                          {article.created.toDate()}
                        </Moment>
                      </Grid>
                    )}
                    <Grid item className={classes.articleTitle}>
                      <Typography align="left" variant="h1">
                        {article.title}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.articleContent}>
                      {article.type === 'video' && (
                        <Typography className={classes.videoDescription}>
                          {article.description}
                        </Typography>
                      )}
                      {article.type !== 'video' && (
                        <div
                          className={classes.articleBody}
                          dangerouslySetInnerHTML={{
                            __html: article.content
                          }}></div>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Actions
                likeValue={likes}
                commentValue={comments.length}
                isLiked={isLiked}
                isSaved={isSaved}
                isAuthor={authUserIsArticleAuthor}
                onLikeClick={this.onArticleLikeClick}
                onSaveClick={this.onSaveClick}
                article={article}
              />
              <div className={classes.commentsContainer}>
                <Comments
                  comments={comments}
                  currentUserId={authUser ? authUser.uid : ''}
                  isAuthUser={!!authUser}
                  showSighInDialog={this.nonSignInHandler}
                  makeNewComment={this.makeNewComment}
                  editComment={this.editComment}
                  deleteComment={this.deleteComment}
                  onLikeClick={this.onCommentLikeClick}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  articles: allArticlesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
  showSighInDialog: (locationState, message) =>
    dispatch({ type: 'SIGN_IN_DIALOG_VISIBLE', locationState, message }),
  onSetSelectedAuthor: author => dispatch({ type: 'AUTHOR_SET', author })
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(ArticlePage));
