export const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 400
  },
  noFollowTitle: {},
  followList: {
    listStyle: 'none'
  }
});

export default styles;
