async function increment(userId, value = 1) {
  if (!userId) {
    return;
  }

  const { firebase } = this.props;
  const userRef = await firebase.user(userId).get();
  const userData = userRef.data();
  const coins = userData.coins || 0;
  const newCoins = coins + value;

  firebase
    .user(userId)
    .set(
      {
        coins: newCoins
      },
      { merge: true }
    )
    .then()
    .catch(err => {
      console.log(err);
    });
}

async function decrement(userId, value = 1) {
  if (!userId) {
    return;
  }

  const { firebase } = this.props;
  const userRef = await firebase.user(userId).get();
  const userData = userRef.data();
  const coins = userData.coins || 0;
  let newCoins = coins !== 0 ? coins - value : coins;

  if (newCoins < 0) {
    newCoins = 0;
  }

  firebase
    .user(userId)
    .set(
      {
        coins: newCoins
      },
      { merge: true }
    )
    .then()
    .catch(err => {
      console.log(err);
    });
}

export default {
  increment,
  decrement
};
