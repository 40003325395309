/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  Button,
  colors,
  Hidden,
  Collapse
} from '@material-ui/core';
import LockOpenSharpIcon from '@material-ui/icons/LockOpenSharp';
import LibraryBooksSharpIcon from '@material-ui/icons/LibraryBooksSharp';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import SignOutPage from '../../../../../../components/SignOut';
import { ARTICLES, SIGN_IN } from '../../../../../../constants/routes';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const {
    pages,
    mobilePages,
    categories,
    authUser,
    onClose,
    onCategoryClose
  } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List className={classes.root}>
      <Hidden lgUp>
        <ListItem className={classes.item} disableGutters key="Articles">
          <Button className={classes.button} onClick={handleClick} exact="true">
            <div className={classes.icon}>
              <LibraryBooksSharpIcon />
            </div>
            Articles
          </Button>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem className={classes.nested} disableGutters key="latest">
              <Button
                className={classes.button}
                component={CustomRouterLink}
                to={ARTICLES}
                onClick={() => onCategoryClose(null)}>
                LATEST
              </Button>
            </ListItem>

            {categories.map(category => (
              <ListItem
                className={classes.nested}
                disableGutters
                key={category.id}>
                <Button
                  className={classes.button}
                  component={CustomRouterLink}
                  to={ARTICLES}
                  onClick={() => onCategoryClose(category.name)}>
                  {category.name}
                </Button>
              </ListItem>
            ))}
          </List>
        </Collapse>

        {mobilePages.map(mobilePage => (
          <ListItem
            className={classes.item}
            disableGutters
            key={mobilePage.title}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to={mobilePage.href}
              onClick={onClose}
              exact>
              <div className={classes.icon}>{mobilePage.icon}</div>
              {mobilePage.title}
            </Button>
          </ListItem>
        ))}
      </Hidden>

      {pages.map(page => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
            onClick={onClose}
            exact>
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}
      <Hidden lgUp>
        {!!authUser && (
          <ListItem className={classes.item} disableGutters key="SignOut">
            <SignOutPage onClose={onClose} />
          </ListItem>
        )}

        {!authUser && (
          <ListItem className={classes.item} disableGutters key="SignIn">
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to={SIGN_IN}
              onClick={onClose}
              exact>
              <div className={classes.icon}>
                <LockOpenSharpIcon />
              </div>
              Sign In
            </Button>
          </ListItem>
        )}
      </Hidden>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
  mobilePages: PropTypes.array.isRequired,
  onClose: PropTypes.func
};

export default SidebarNav;
