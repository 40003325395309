export const styles = theme => ({
  contentContainer: {
    padding: theme.spacing(1)
  },
  videoContainer: {
    marginBottom: theme.spacing(1),
    borderRadius: 4,
    overflow: 'hidden'
  },
  videoPlayer: {
    height: 240,
    '& iframe': {
      display: 'block'
    }
  },
  mediaContainer: {
    position: 'relative',
    height: 252,
    borderRadius: '4px',
    overflow: 'hidden',
    marginBottom: theme.spacing(1)
  },
  postingTime: {
    display: 'inline-block',
    marginBottom: theme.spacing(0.5),
    fontFamily: 'Roboto',
    fontSize: 10,
    lineHeight: 1.25,
    letterSpacing: 0.27,
    color: 'inherit'
  },
  imageContainer: {
    height: '100%'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  mediaTopContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1)
  },
  mediaBottomContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1)
  },
  readingTimeIcon: {
    fontSize: 16
  },
  readingTimeValue: {
    marginLeft: 3,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit'
  },
  tagList: {
    listStyle: 'none'
  },
  tagItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 6px',
    fontSize: 10,
    lineHeight: '1em',
    borderRadius: '16px',
    backgroundColor: '#ffffff'
  },
  contributorContainer: {
    width: 'auto',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '4px'
  },
  actionsContainer: {
    borderTop: `1px solid ${theme.palette.border}`,
    color: theme.palette.text.secondary
  },
  likeButton: {
    padding: 0,
    fontSize: 12,
    color: 'inherit',
    borderRadius: 0
  },
  likeIcon: {
    fontSize: '1.75em',
    '&.liked': {
      color: theme.palette.text.brand
    }
  },
  likeValue: {
    marginLeft: theme.spacing(0.5),
    fontSize: 13,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: 'inherit'
  },
  commentButton: {
    padding: 0,
    fontSize: 12,
    color: 'inherit',
    borderRadius: 0
  },
  commentIcon: {
    fontSize: 21
  },
  commentValue: {
    marginLeft: theme.spacing(0.5),
    fontSize: 13,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: 'inherit'
  },
  avatar: {
    width: 40,
    height: 40
  },
  category: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  favoriteBtn: {
    padding: 0,
    fontSize: 12,
    lineHeight: '16px',
    color: 'inherit',
    textTransform: 'uppercase',
    borderRadius: 0
  },
  bookmarkIcon: {
    fontSize: '1.75em',
    '&.saved': {
      color: theme.palette.text.brand
    }
  }
});

export default styles;
