import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Snackbar
} from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';

import { withFirebase } from '../../Firebase';

const styles = theme => ({
  root: {},
  contentContainer: {},
  content: {
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(5)
    }
  },
  contentHeader: {
    display: 'flex',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  changeButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
});

const INITIAL_STATE = {
  password: '',
  emailNew: '',
  error: null,
  openError: false,
  openSuccess: false,
  success: null
};

/*
Error Codes:

auth/invalid-email
Thrown if the email used is invalid.

auth/email-already-in-use
Thrown if the email is already used by another user.

auth/requires-recent-login
Thrown if the user's last sign-in time does not meet the security threshold. 
Use firebase.User.reauthenticateWithCredential to resolve. 
This does not apply if the user is anonymous.
*/

class EmailChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.setState({
      emailNew: this.props.authUser.email
    });
  }

  onSubmit = event => {
    const { password, emailNew } = this.state;
    const { authUser } = this.props;

    const errors = {
      'auth/invalid-email': 'Email address invalid',
      'auth/email-already-in-use':
        'Email address already used by another account'
    };

    this.props.firebase
      .doSignInWithEmailAndPassword(authUser.email, password)
      .then(userCredential => {
        userCredential.user
          .updateEmail(emailNew)
          .then(() => {
            this.props.firebase
              .user(this.props.authUser.uid)
              .set(
                {
                  email: emailNew
                },
                { merge: true }
              )
              .then(() => {
                authUser.email = emailNew;

                localStorage.setItem('authUser', JSON.stringify(authUser));
                this.props.onSetAuthUser(authUser);

                this.setState({
                  success: {
                    message: 'Email updated successfully'
                  },
                  openSuccess: true
                });
              })
              .catch(error => {
                console.log('error :', error);
                this.setState({ error, openError: true });
              });
          })
          .catch(error => {
            error.message = errors[error.code];

            console.log('Change email error : ', error);

            this.setState({ error, openError: true });
          });
      })
      .catch(error => {
        this.setState({ error, openError: true });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      openError: false,
      openSuccess: false
    });
  };

  render() {
    const {
      password,
      emailNew,
      openError,
      error,
      openSuccess,
      success
    } = this.state;
    const { classes } = this.props;

    const isInvalid = password === '' || emailNew === '';

    return (
      <Grid className={classes.content} item lg={6} xs={12}>
        <Card>
          <form autoComplete="off" noValidate onSubmit={this.onSubmit}>
            <CardHeader title="Change Email" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="password"
                    label="Current Password"
                    name="password"
                    variant="outlined"
                    value={password}
                    type="password"
                    onChange={this.onChange}
                    margin="dense"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="emailNew"
                    label="Email Address"
                    margin="dense"
                    name="emailNew"
                    onChange={this.onChange}
                    value={emailNew}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isInvalid}
                className={classes.changeButton}>
                Update Email
              </Button>
            </CardActions>
          </form>
        </Card>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          onClose={this.handleClose}
          autoHideDuration={3000}>
          <Alert variant="filled" severity="error" onClose={this.handleClose}>
            <AlertTitle>Error</AlertTitle>
            {error && error.message}
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSuccess}
          onClose={this.handleClose}
          autoHideDuration={3000}>
          <Alert variant="filled" severity="success" onClose={this.handleClose}>
            {success && success.message}
          </Alert>
        </Snackbar>
      </Grid>
    );
  }
}

EmailChangeForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser })
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(withStyles(styles)(EmailChangeForm));
