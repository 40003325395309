import React, { Component } from 'react';
import { LinearProgress, Grid, Typography } from '@material-ui/core';

import { Notification } from './notification';

class NotificationBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    const { authUser, notifications, firebase, onSetAuthUser } = this.props;
    const isNewNotification =
      notifications.length > 0 ? notifications.some(n => !n.hasRead) : false;

    if (isNewNotification) {
      const updatedNotifocations = {
        ...authUser.notifications
      };

      notifications.forEach(({ uid, hasRead }) => {
        if (!hasRead) {
          updatedNotifocations[uid].hasRead = true;
        }
      });

      firebase
        .user(authUser.uid)
        .set(
          {
            notifications: updatedNotifocations
          },
          { merge: true }
        )
        .then(() => {
          onSetAuthUser({
            ...authUser,
            notifications: updatedNotifocations
          });

          this.setState({
            loading: false
          });

          return;
        })
        .catch(err => {
          console.error('error: ', err);
        });
    }

    this.setState({
      loading: false
    });
  }

  render() {
    const { classes, notifications } = this.props;
    const { loading } = this.state;

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {!loading && (
          <div className={classes.root}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography className={classes.pageTitle} variant="h1">
                  Notifications
                </Typography>
              </Grid>
              {notifications.length === 0 && (
                <Grid item>
                  <Typography
                    className={classes.noContentTitle}
                    align="center"
                    variant="body1">
                    There are no new notifications
                  </Typography>
                </Grid>
              )}
              {notifications.length > 0 && (
                <Grid
                  className={classes.notificationList}
                  item
                  container
                  component="ul"
                  direction="column">
                  {notifications.map(({ uid, type, user, post, created }) => {
                    return (
                      <Grid
                        className={classes.notificationItem}
                        key={uid}
                        item
                        component="li">
                        <Notification
                          type={type}
                          user={user}
                          post={post}
                          date={created}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default NotificationBase;
