export const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2)
  },
  waiting: {
    display: 'flex',
    justifyContent: 'center'
  },
  pageTitle: {
    fontWeight: 400,
    letterSpacing: 0
  },
  form: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    flexGrow: 1,
    marginLeft: theme.spacing(1.75)
  },
  searchButton: {
    padding: 8,
    fontSize: 20
  },
  inputHelper: {
    margin: 0,
    padding: theme.spacing(0, 1.75),
    fontSize: 12,
    letterSpacing: 0.4
  },
  showMoreGrid: {
    marginTop: theme.spacing(3)
  }
});

export default styles;
