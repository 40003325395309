import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/styles';
import { Grid, CardActions, Typography, IconButton } from '@material-ui/core';

import { Bookmark, ThumbUp } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';

import { styles } from './Actions.style';
import { Share } from '../share';

const Actions = ({
  classes,
  likeValue,
  commentValue,
  isLiked,
  isSaved,
  isAuthor,
  onLikeClick,
  onSaveClick,
  article
}) => {
  return (
    <CardActions className={classes.actionsContainer}>
      <Grid container wrap="nowrap" spacing={2} justify="space-between">
        <Grid item>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item>
              <IconButton
                className={classes.likeButton}
                disabled={isAuthor}
                onClick={onLikeClick}>
                <ThumbUp
                  className={clsx({
                    [classes.likeIcon]: true,
                    liked: isLiked
                  })}
                />
                <Typography className={classes.likeValue} component="span">
                  {likeValue}
                </Typography>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton className={classes.commentButton} disableRipple>
                <FontAwesomeIcon
                  className={classes.commentIcon}
                  icon={faComment}
                />
                <Typography className={classes.commentValue} component="span">
                  {commentValue}
                </Typography>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Share article={article} />
          {!isAuthor && (
            <span>
              &nbsp;|&nbsp;
              <IconButton className={classes.favoriteBtn} onClick={onSaveClick}>
                {isSaved ? 'Saved' : 'Save'}
                <Bookmark
                  className={clsx({
                    [classes.bookmarkIcon]: true,
                    saved: isSaved
                  })}
                />
              </IconButton>
            </span>
          )}
        </Grid>
      </Grid>
    </CardActions>
  );
};

Actions.propTypes = {
  classes: PropTypes.object.isRequired,
  likeValue: PropTypes.number,
  commentValue: PropTypes.number,
  isLiked: PropTypes.bool,
  isSaved: PropTypes.bool,
  onLikeClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired
};

Actions.defaultProps = {
  likeValue: 0,
  commentValue: 0,
  isLiked: false,
  isSaved: false
};

export default withStyles(styles)(Actions);
