import React, { useState } from 'react';

import { Grid, Typography, List } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { Comment } from './comment';
import { styles, NewCommentArea, ActionButton } from './Comments.style';

const COMMENT_ROWS_NUMBER = 3;

const Comments = ({
  classes,
  comments,
  currentUserId,
  isAuthUser,
  showSighInDialog,
  makeNewComment,
  editComment,
  deleteComment,
  onLikeClick
}) => {
  const [writting, setWritting] = useState(false);
  const [newComment, setNewComment] = useState('');

  const commentsAmount = comments.length;

  comments.sort((a, b) => {
    return a.date - b.date;
  });

  const onChangeNewComment = e => {
    setNewComment(e.target.value);
  };

  const onFocusNewComment = () => {
    if (!isAuthUser) {
      const textArea = document.getElementById('comment-text-area');
      textArea.blur();
      showSighInDialog('make a response');

      return;
    }

    setWritting(true);
  };

  const onBlurNewComment = () => {
    if (!newComment) setWritting(false);
  };

  const onCancelClick = () => {
    setNewComment('');
    setWritting(false);
  };

  const onPostClick = () => {
    makeNewComment(newComment);
    setNewComment('');
    setWritting(false);
  };

  const onUpdateClick = (commentId, updatedComment) => {
    editComment(commentId, updatedComment);
  };

  const onDeleteClick = commentId => {
    deleteComment(commentId);
  };

  return (
    <Grid className={classes.root} container direction="column">
      <Grid item>
        <Typography component="h3" className={classes.title}>
          Responses
        </Typography>
        <Typography component="h4" className={classes.subtitle}>
          {`${commentsAmount} responses`}
        </Typography>
      </Grid>

      <Grid item>
        {commentsAmount === 0 && (
          <Typography className={classes.emptyCommentMessage} align="center">
            There are no responses yet. Be the first!
          </Typography>
        )}
        {commentsAmount > 0 && (
          <List>
            {comments.map(comment => {
              const { uid, user, content, likes, date, isEdited } = comment;
              const avatar = user.avatar;
              const fullName =
                user.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : user.username;
              const likeValue = likes ? likes.length : 0;
              const userId = user.uid !== currentUserId ? user.uid : null;
              const isCommentOwner = user.uid === currentUserId;
              const isLiked = likes.includes(currentUserId);

              return (
                <Comment
                  key={uid}
                  id={uid}
                  userId={userId}
                  avatar={avatar}
                  fullName={fullName}
                  comment={content}
                  date={date.toDate()}
                  likeValue={likeValue}
                  isEdited={isEdited || false}
                  isEditable={isCommentOwner}
                  isLikeble={!isCommentOwner}
                  isLiked={isLiked}
                  onUpdateClick={onUpdateClick}
                  onDeleteClick={onDeleteClick}
                  onLikeClick={onLikeClick}
                />
              );
            })}
          </List>
        )}
      </Grid>

      <Grid container item spacing={1}>
        <Grid item xs={12}>
          <NewCommentArea
            id="comment-text-area"
            multiline
            value={newComment}
            rows={COMMENT_ROWS_NUMBER}
            variant="outlined"
            placeholder="Add response"
            onFocus={onFocusNewComment}
            onBlur={onBlurNewComment}
            onChange={onChangeNewComment}
          />
        </Grid>
        {writting && (
          <Grid container item spacing={1} justify="flex-end">
            <Grid item>
              <ActionButton
                className={classes.cancelBtn}
                variant="contained"
                disabled={!newComment}
                onClick={onCancelClick}>
                Cancel
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton
                className={classes.postBtn}
                variant="contained"
                disabled={!newComment}
                onClick={onPostClick}>
                Post
              </ActionButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Comments);
