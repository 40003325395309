import React, { Component } from 'react';
import { LinearProgress, Typography, Grid } from '@material-ui/core';

import FollowerCard from './FollowerCard';

class FollowingBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      following: null,
      loading: true
    };
  }

  componentDidMount() {
    const { authUser } = this.props;

    if (authUser && authUser.follows && authUser.follows.length > 0) {
      this.getFollowing();

      return;
    }

    this.setState({
      loading: false
    });
  }

  getFollowing = async () => {
    const { authUser, firebase } = this.props;
    const following = {};

    let query = firebase.users();
    query = query.where(
      firebase.fieldPath.documentId(),
      'in',
      authUser.follows
    );

    const followingRefs = await query.get();
    followingRefs.forEach(s => {
      following[s.id] = s.data();
    });

    this.setState({
      following,
      loading: false
    });
  };

  doUnfollow = (e, followUserId, followUserFollowers) => {
    e.preventDefault();
    const { authUser, firebase, onSetAuthUser } = this.props;
    const { following } = this.state;

    const newUserFollows = authUser.follows.filter(id => id !== followUserId);
    const newFollowUserFollowers = followUserFollowers.filter(
      id => id !== authUser.uid
    );

    firebase
      .user(authUser.uid)
      .set(
        {
          follows: newUserFollows
        },
        { merge: true }
      )
      .then(() => {
        const newFollowing = { ...following };
        delete newFollowing[followUserId];
        onSetAuthUser({
          ...authUser,
          follows: newUserFollows
        });
        this.setState({
          following: newFollowing
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });

    firebase
      .user(followUserId)
      .set(
        {
          followers: newFollowUserFollowers
        },
        { merge: true }
      )
      .then()
      .catch(err => {
        console.log('error: ', err);
      });
  };

  render() {
    const { classes, authUser } = this.props;
    const { loading, following } = this.state;
    const sortedFollowing = [];

    if (following) {
      const follows = [...authUser.follows];
      follows.reverse().forEach(followId => {
        sortedFollowing.push({
          uid: followId,
          ...following[followId]
        });
      });
    }

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}
        {!loading && (
          <div className={classes.root}>
            <Typography className={classes.pageTitle} variant="h1">
              People you follow
            </Typography>
            {sortedFollowing.length === 0 && (
              <Typography
                className={classes.noFollowTitle}
                align="center"
                variant="body1">
                You aren&apos;t following any people yet
              </Typography>
            )}
            {sortedFollowing.length > 0 && (
              <Grid className={classes.followList} container>
                {sortedFollowing.map(
                  ({
                    uid,
                    avatar,
                    firstName,
                    lastName,
                    username,
                    memberStatus,
                    occupation,
                    description,
                    followers
                  }) => {
                    const fullName =
                      firstName && lastName
                        ? `${firstName} ${lastName}`
                        : username;

                    let status = memberStatus || '';
                    if (occupation) {
                      status =
                        status !== ''
                          ? `${status} | ${occupation}`
                          : `${occupation}`;
                    }

                    return (
                      <Grid key={uid} item lg={4} md={12} xs={12}>
                        <FollowerCard
                          userId={uid}
                          avatar={avatar}
                          fullName={fullName}
                          status={status}
                          description={description}
                          isFollowed={true}
                          onFollowClick={e =>
                            this.doUnfollow(e, uid, followers)
                          }
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default FollowingBase;
