import { withStyles } from '@material-ui/styles';
import { TabPanel, TabList } from '@material-ui/lab';
import { Button, Tab } from '@material-ui/core';

export const styles = theme => ({
  root: {
    padding: theme.spacing(2, 2, 0)
  },
  mainContainer: {
    marginBottom: theme.spacing(3)
  },
  userCard: {
    padding: theme.spacing(2)
  },
  userCardHeader: {
    alignItems: 'center'
  },
  userAvatar: {
    width: 71,
    height: 71
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  userDescription: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '4px',
    marginTop: theme.spacing(1)
  },
  statistic: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    textAlign: 'center',
    fontSize: 18,
    color: theme.palette.text.brand
  },
  likeValue: {
    fontSize: 12,
    lineHeight: 1.3334,
    color: 'inherit'
  },
  statisticIcon: {
    display: 'block',
    fontSize: 20
  },
  followValue: {
    fontSize: 12,
    lineHeight: 1.3334,
    color: 'inherit',
    textTransform: 'uppercase'
  },
  articleListTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 400
  }
});

export const StyledTabList = withStyles(theme => ({
  root: {
    minHeight: 32,
    color: theme.palette.text.secondary,
    opacity: 1
  },
  indicator: {
    backgroundColor: theme.palette.background.brand
  }
}))(TabList);

export const StyledTabPanel = withStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0)
  }
}))(TabPanel);

export const StyledTab = withStyles(theme => ({
  root: {
    minHeight: 32,
    fontSize: 14,
    lineHeight: 1.3572,
    letterSpacing: 1.25,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.text.secondary
  },
  selected: {
    color: theme.palette.text.brand,
    backgroundColor: `${theme.palette.background.brand}1f` // #64A6B81f
  }
}))(Tab);

export const StyledButton = withStyles(() => ({
  root: {
    '&.following': {
      backgroundColor: '#8E6C88'
    }
  }
}))(Button);

export default styles;
