import { createSelector } from 'reselect';
import { authUserSelector } from '../Session/selectors';

export const allNotificationSelector = createSelector(
  authUserSelector,
  authUser => {
    const notifications = [];
    if (!authUser || !authUser.notifications) {
      return notifications;
    }

    for (const [key, value] of Object.entries(authUser.notifications)) {
      notifications.push({
        uid: key,
        ...value
      });
    }

    return notifications.sort((a, b) => {
      return b.created.seconds - a.created.seconds;
    });
  }
);

export const newNotificationsAmount = createSelector(
  allNotificationSelector,
  notifications => {
    const newNotifications = notifications.filter(n => !n.hasRead);
    return newNotifications.length;
  }
);

export default {
  allNotificationSelector,
  newNotificationsAmount
};
