import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase/context';

import { authUserSelector } from '../Session/selectors';

import NewPostBase from './NewPost';
import { styles } from './NewPost.style';

const mapStateToProps = state => ({
  authUser: authUserSelector(state)
});

const mapStateToDispatch = dispatch => ({
  showAlert: alert => dispatch({ type: 'ALERT_SET', alert })
});

const condition = authUser => !!authUser;

const NewPostPage = compose(
  connect(mapStateToProps, mapStateToDispatch),
  withAuthorization(condition),
  withFirebase
)(withStyles(styles)(NewPostBase));

export default NewPostPage;
