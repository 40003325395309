import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Snackbar,
  IconButton,
  Button,
  Hidden,
  colors
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { withStyles } from '@material-ui/styles';

import { Alert, AlertTitle } from '@material-ui/lab';

import { withFirebase } from '../Firebase';
import { LANDING } from '../../constants/routes';

const styles = theme => ({
  root: {
    boxShadow: 'none'
  },
  main: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    color: '#fff',
    textDecoration: 'none'
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  }
});

const SignOutPage = props => <SignOut {...props} />;

const INITIAL_STATE = {
  error: null,
  openAlert: false
};

class SignOutButton extends Component {
  constructor(props) {
    super(props);

    this.signOut = this.signOut.bind(this);
    this.state = { ...INITIAL_STATE };
  }

  signOut = event => {
    this.props.firebase
      .doSignOut()
      .then(() => {
        if (typeof this.props.onClose === 'function') {
          if (this.props.onClose()) {
            this.props.onClose();
          }
        }

        this.props.history.push(LANDING);
      })
      .catch(error => {
        console.log('error :', error);
        this.setState({ error, openAlert: true });
      });

    event.preventDefault();
  };

  render() {
    const { classes } = this.props;

    const { error, openAlert } = this.state;

    return (
      <div className={classes.main}>
        <Hidden mdDown>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={this.signOut}>
            <InputIcon />
          </IconButton>
        </Hidden>

        <Hidden lgUp>
          <Button className={classes.button} onClick={this.signOut}>
            <div className={classes.icon}>
              <InputIcon />
            </div>
            Sign out
          </Button>
        </Hidden>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          onClose={this.handleClose}
          autoHideDuration={3000}>
          <Alert variant="filled" severity="error" onClose={this.handleClose}>
            <AlertTitle>Error</AlertTitle>
            {error && error.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const SignOut = compose(withFirebase)(withStyles(styles)(SignOutButton));

SignOutButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

export default withRouter(SignOutPage);

export { SignOut };
