import React, { Component } from 'react';
import uniqid from 'uniqid';
import { Link as RouterLink } from 'react-router-dom';
import { getBase64Strings } from 'exif-rotate-js/lib';
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Link,
  CircularProgress,
  LinearProgress,
  CardContent
} from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import { getStrippedString } from '../../common/Helpers';

import { TextField } from './components';
import { StyledRadio, ActionButton } from './NewPost.style';

import { ARTICLES } from '../../constants/routes';

const INITIAL_STATE = {
  loading: false,
  imageLoading: false,
  progress: 1,
  type: 'question',
  isValidate: false,
  isPublished: false,
  publishedPostID: '',
  question: {
    title: '',
    content: ''
  },
  thought: {
    content: ''
  },
  video: {
    title: '',
    description: '',
    url: ''
  },
  article: {
    title: '',
    description: '',
    content: '',
    banner: ''
  }
};

class NewPostBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  toValidate = date => {
    const keys = Object.keys(date);
    return keys.every(key => {
      if (key === 'banner') {
        return true;
      }

      return date[key];
    });
  };

  handleTypeChange = event => {
    const type = event.target.value;
    const isValidate = this.toValidate(this.state[type]);

    this.setState({
      type,
      isValidate
    });
  };

  handleTextChange = event => {
    const { type } = this.state;
    const data = {
      ...this.state[type],
      [event.target.name]: event.target.value
    };

    this.setState({
      [type]: data,
      isValidate: this.toValidate(data)
    });
  };

  handleImageChange = e => {
    const { files } = e.target;

    if (!files || files.length < 1) {
      return;
    }

    const image = files[0];
    if (
      !image.type.includes('jpeg') &&
      !image.type.includes('jpg') &&
      !image.type.includes('png') &&
      !image.type.includes('gif')
    ) {
      const error = { type: 'error', message: 'Only images are allowed' };
      this.props.showAlert(error);
      return;
    }

    this.setState(() => ({ imageLoading: true }));
    const { firebase } = this.props;
    const { article } = this.state;
    const imageName = article.title
      .toLowerCase()
      .split(' ')
      .join('-');

    getBase64Strings(files, { maxSize: 720 })
      .then(result => {
        const uploadTask = firebase.storage
          .ref(`articles/${imageName}`)
          .putString(result[0], 'data_url', { contentType: image.type });

        uploadTask.on(
          'state_changed',
          snapshot => {
            // progress function ...
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            this.setState({ progress });
          },
          error => {
            // Error function ...
            console.log(error);
          },
          () => {
            // complete function ...
            firebase.storage
              .ref('articles')
              .child(imageName)
              .getDownloadURL()
              .then(url => {
                this.setState({
                  article: {
                    ...article,
                    banner: url
                  },
                  imageLoading: false,
                  progress: 1
                });
              });
          }
        );
      })
      .catch(err => {
        const error = { type: 'error', message: err };
        this.props.showAlert(error);
        this.setState({
          imageLoading: false,
          progress: 1
        });
      });
  };

  handleCancelOnClick = () => {
    const { firebase } = this.props;
    const { article } = this.state;

    if (article.banner) {
      firebase.storage
        .refFromURL(article.banner)
        .delete()
        .then()
        .catch(err => {
          console.error('error: ', err);
        });
    }

    this.setState({
      ...INITIAL_STATE
    });
  };

  handlePostOnClick = () => {
    const { type } = this.state;

    this.preparePost(type);
    this.setState({
      loading: true
    });
  };

  preparePost = type => {
    const { authUser, firebase } = this.props;
    const post = {
      contributorRef: authUser.uid,
      created: firebase.timeStamp.fromDate(new Date()),
      status: 'published',
      likesCount: 0,
      type
    };

    if (type === 'question') {
      post.title = this.state[type].title;
      post.content = this.state[type].content;
    }

    if (type === 'article') {
      post.title = this.state[type].title;
      post.content = this.state[type].content;
      post.description = this.state[type].description;
      if (this.state[type].banner) {
        post.banner = this.state[type].banner;
      }
    }

    if (type === 'video') {
      post.title = this.state[type].title;
      post.description = this.state[type].description;
      post.content = this.state[type].url;
    }

    if (type === 'thought') {
      post.content = this.state[type].content;
    }

    this.toPublishPost(post);
  };

  toPublishPost = post => {
    const { authUser, firebase } = this.props;

    firebase
      .articles()
      .add(post)
      .then(ref => {
        if (authUser.followers && authUser.followers.length > 0) {
          this.makeNotification(ref.id);
        }

        this.setState({
          loading: false,
          isPublished: true,
          publishedPostID: ref.id
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  makeNotification = postId => {
    const MAX_CHARACTERS = 30;
    const { authUser, firebase } = this.props;
    const { type } = this.state;
    const post = this.state[type];
    const { avatar, firstName, lastName, username } = authUser;
    const title = post.title || post.content;

    const notification = {
      type: 'post',
      id: uniqid(),
      created: firebase.timeStamp.fromDate(new Date()),
      user: {
        avatar: avatar || '',
        uid: authUser.uid,
        fullName: firstName && lastName ? `${firstName} ${lastName}` : username
      },
      post: {
        uid: postId,
        title: getStrippedString(title, MAX_CHARACTERS)
      },
      hasRead: false
    };

    this.sendNotification(notification);
  };

  sendNotification = async notification => {
    const { authUser, firebase } = this.props;
    const followers = [...authUser.followers];

    const { id: key, ...value } = notification;

    followers.forEach(userId => {
      firebase
        .user(userId)
        .get()
        .then(userRef => {
          const user = userRef.data();
          const notifications = user.notifications || {};
          const newNotifications = {
            ...notifications,
            [key]: value
          };

          firebase.user(userId).set(
            {
              notifications: newNotifications
            },
            { merge: true }
          );
        })
        .catch(err => {
          console.error('error: ', err);
        });
    });
  };

  render() {
    const { classes } = this.props;
    const {
      type,
      question,
      thought,
      video,
      article,
      loading,
      imageLoading,
      progress,
      isValidate,
      isPublished,
      publishedPostID
    } = this.state;

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}

        <div className={classes.root}>
          {isPublished && (
            <Card className={classes.successMessage}>
              <CardContent className={classes.successMessageContent}>
                <Typography className={classes.successMessageTitle}>
                  Post submitted!
                </Typography>
              </CardContent>
              <CardActions className={classes.successMessageActions}>
                <Button
                  className={classes.viewPostBtn}
                  variant="contained"
                  component={RouterLink}
                  to={`${ARTICLES}/${publishedPostID}`}>
                  View post
                </Button>
              </CardActions>
            </Card>
          )}
          {!isPublished && (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.pageTitle} variant="h1">
                  Add new post
                </Typography>
              </Grid>
              <Grid item>
                <Card className={classes.form}>
                  <Grid container direction="column">
                    <Grid item className={classes.typeGrid}>
                      <FormControl className={classes.typeContainer}>
                        <FormLabel className={classes.typeLabel}>
                          Post type
                        </FormLabel>
                        <RadioGroup
                          className={classes.typeList}
                          aria-label="Post type"
                          name="type"
                          value={type}
                          onChange={this.handleTypeChange}>
                          <FormControlLabel
                            className={classes.typeItem}
                            value="question"
                            control={<StyledRadio disableRipple />}
                            label="Q&amp;A"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            className={classes.typeItem}
                            value="article"
                            control={<StyledRadio disableRipple />}
                            label="Article"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            className={classes.typeItem}
                            value="video"
                            control={<StyledRadio disableRipple />}
                            label="Video"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            className={classes.typeItem}
                            value="thought"
                            control={<StyledRadio disableRipple />}
                            label="Thought"
                            labelPlacement="bottom"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {type === 'question' && (
                      <React.Fragment>
                        <Grid item className={classes.fieldGrid}>
                          <TextField
                            name="title"
                            label="Title of your question"
                            value={question.title}
                            onChange={this.handleTextChange}
                          />
                        </Grid>
                        <Grid item className={classes.fieldGrid}>
                          <TextField
                            multiline
                            rows={3}
                            name="content"
                            label="What’s your question?"
                            value={question.content}
                            onChange={this.handleTextChange}
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                    {type === 'thought' && (
                      <Grid item className={classes.fieldGrid}>
                        <TextField
                          multiline
                          rows={4}
                          name="content"
                          label="What’s your thought?"
                          value={thought.content}
                          onChange={this.handleTextChange}
                        />
                      </Grid>
                    )}
                    {type === 'video' && (
                      <React.Fragment>
                        <Grid item className={classes.fieldGrid}>
                          <TextField
                            name="title"
                            label="Title"
                            value={video.title}
                            onChange={this.handleTextChange}
                          />
                        </Grid>
                        <Grid item className={classes.fieldGrid}>
                          <TextField
                            multiline
                            rows={3}
                            name="description"
                            label="Description"
                            value={video.description}
                            onChange={this.handleTextChange}
                          />
                        </Grid>
                        <Grid item className={classes.fieldGrid}>
                          <TextField
                            name="url"
                            type="url"
                            label="YouTube link to Video"
                            value={video.url}
                            onChange={this.handleTextChange}
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                    {type === 'article' && (
                      <React.Fragment>
                        <Grid item className={classes.fieldGrid}>
                          <TextField
                            name="title"
                            label="Title"
                            value={article.title}
                            onChange={this.handleTextChange}
                          />
                        </Grid>
                        <Grid item className={classes.fieldGrid}>
                          <TextField
                            multiline
                            rows={3}
                            name="description"
                            label="Description"
                            value={article.description}
                            onChange={this.handleTextChange}
                          />
                        </Grid>
                        <Grid item className={classes.fieldGrid}>
                          <TextField
                            multiline
                            rows={3}
                            name="content"
                            label="Article Body"
                            value={article.content}
                            onChange={this.handleTextChange}
                          />
                        </Grid>
                        <Grid item className={classes.fieldGrid}>
                          <Typography
                            className={classes.photoLabel}
                            component="label">
                            Featured Image
                          </Typography>
                          <CardActionArea
                            className={classes.photoContainer}
                            component="label"
                            disabled={!article.title || imageLoading}>
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              accept="image/*"
                              onChange={this.handleImageChange}
                            />
                            {imageLoading && (
                              <div className={classes.imageLoader}>
                                <CircularProgress
                                  variant="static"
                                  value={progress}
                                />
                              </div>
                            )}
                            {!imageLoading && article.banner && (
                              <CardMedia
                                className={classes.photoImage}
                                image={article.banner}
                                component="img"
                              />
                            )}
                            <Button
                              className={classes.photoUploadButton}
                              fullWidth
                              variant="text"
                              startIcon={<PhotoCameraIcon />}
                              component="span">
                              {`Click to ${
                                article.banner ? 'change' : 'add'
                              } image`}
                            </Button>
                          </CardActionArea>
                          <Typography className={classes.photoAdditional}>
                            Need great images? checkout&thinsp;
                            <Link
                              href="https://www.pexels.com/"
                              target="_blank"
                              rel="noopener">
                              pexels.com
                            </Link>
                            &sbquo;&thinsp;
                            <Link
                              href="https://pixabay.com/"
                              target="_blank"
                              rel="noopener">
                              pixabay.com
                            </Link>
                            &thinsp;or&thinsp;
                            <Link
                              href="https://unsplash.com/"
                              target="_blank"
                              rel="noopener">
                              unsplash.com
                            </Link>
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                </Card>
              </Grid>
              <Grid container item spacing={1} justify="flex-end">
                <Grid item>
                  <ActionButton
                    className={classes.cancelBtn}
                    variant="contained"
                    disabled={!isValidate}
                    onClick={this.handleCancelOnClick}>
                    Cancel
                  </ActionButton>
                </Grid>
                <Grid item>
                  <ActionButton
                    className={classes.postBtn}
                    variant="contained"
                    disabled={!isValidate}
                    onClick={this.handlePostOnClick}>
                    Post
                  </ActionButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

export default NewPostBase;
