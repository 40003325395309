import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Moment from 'react-moment';
import { Link as RouterLink } from 'react-router-dom';

import {
  Grid,
  ListItem,
  Avatar,
  Typography,
  IconButton,
  Popover
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { ThumbUp } from '@material-ui/icons';

import { AUTHOR } from '../../../../../constants/routes';
import { MoreOptions } from '../../moreOptions';
import { styles, EditorTextArea, ActionButton } from './Comment.style';

const EDITOR_ROWS_NUMBER = 3;

const Comment = ({
  classes,
  id,
  userId,
  avatar,
  fullName,
  comment,
  date,
  likeValue,
  isEdited,
  isEditable,
  isLikeble,
  isLiked,
  onUpdateClick,
  onDeleteClick,
  onLikeClick
}) => {
  const [optionAnchorEl, setOptionAnchorEl] = useState(null);
  const [isEditorOpen, setEditorOpen] = useState(false);
  const [editedComment, setEditedComment] = useState(comment);
  const editorAnchorRef = useRef();

  const handleOptionsClick = e => {
    setOptionAnchorEl(e.currentTarget);
  };

  const handleOptionsClose = () => {
    setOptionAnchorEl(null);
  };

  const handleEditClick = () => {
    setEditorOpen(true);
    handleOptionsClose();
  };

  const handleEditorClose = () => {
    setEditorOpen(false);
    setEditedComment(comment);
  };

  const onChangeEditedComment = e => {
    setEditedComment(e.target.value);
  };

  const handleUpdateClick = () => {
    onUpdateClick(id, editedComment);
    setEditorOpen(false);
  };

  const handleDeleteClick = () => {
    onDeleteClick(id);
    handleOptionsClose();
  };

  const editorId = isEditorOpen ? 'editor-area' : undefined;

  return (
    <ListItem disableGutters>
      <Grid container spacing={1}>
        <Grid item>
          <Avatar
            src={avatar}
            className={classes.avatar}
            alt=""
            component={userId ? RouterLink : 'div'}
            to={userId ? `${AUTHOR}/${userId}` : null}
          />
        </Grid>
        <Grid
          ref={editorAnchorRef}
          container
          item
          xs
          direction="column"
          spacing={1}>
          <Grid container item spacing={1} alignItems="baseline">
            <Grid item>
              <Typography className={classes.name} component="h5">
                {!userId && fullName}
                {userId && (
                  <RouterLink to={`${AUTHOR}/${userId}`}>{fullName}</RouterLink>
                )}
              </Typography>
            </Grid>
            <Grid item className={classes.period}>
              <Moment fromNow>{date}</Moment>
              {isEdited && <span> | Edited</span>}
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{comment}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              className={classes.likeButton}
              onClick={() => onLikeClick(id)}
              disabled={!isLikeble}>
              <ThumbUp
                className={clsx({
                  [classes.likeIcon]: true,
                  liked: isLiked
                })}
              />
              <Typography className={classes.likeValue} component="span">
                {likeValue}
              </Typography>
            </IconButton>
          </Grid>
          <Popover
            id={editorId}
            className={classes.editorContainer}
            anchorEl={editorAnchorRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: -16,
              horizontal: 'center'
            }}
            open={isEditorOpen}
            onClose={handleEditorClose}>
            <Grid container item spacing={1}>
              <Grid item xs={12}>
                <EditorTextArea
                  multiline
                  value={editedComment}
                  rows={EDITOR_ROWS_NUMBER}
                  variant="outlined"
                  onChange={onChangeEditedComment}
                />
              </Grid>
              <Grid container item spacing={1} justify="flex-end">
                <Grid item>
                  <ActionButton
                    className={classes.cancelBtn}
                    variant="contained"
                    onClick={handleEditorClose}>
                    Cancel
                  </ActionButton>
                </Grid>
                <Grid item>
                  <ActionButton
                    className={classes.updateBtn}
                    variant="contained"
                    disabled={!editedComment}
                    onClick={handleUpdateClick}>
                    Update
                  </ActionButton>
                </Grid>
              </Grid>
            </Grid>
          </Popover>
        </Grid>
        {isEditable && (
          <Grid item>
            <MoreOptions
              size="small"
              anchorEl={optionAnchorEl}
              onOptionsClick={handleOptionsClick}
              onOptionClose={handleOptionsClose}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
            />
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};

Comment.propTypes = {
  classes: PropTypes.object.isRequired,
  avatar: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  likeValue: PropTypes.number.isRequired,
  isEdited: PropTypes.bool,
  onUpdateClick: PropTypes.func
};

export default withStyles(styles)(Comment);
