import { Tabs, Tab, Badge, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const styles = theme => ({
  root: {
    top: 'auto',
    bottom: 0,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.topBar
  },
  tab: {
    fontSize: 24
  },
  spacer: {
    fontSize: 10,
    backgroundColor: '#f4f6f8',
    paddingBottom: 0
  }
});

export const StyledTabs = withStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.primary.main
  }
}))(Tabs);

export const StyledTab = withStyles(theme => ({
  root: {
    '&.Mui-selected': {
      color: theme.palette.text.brand
    }
  }
}))(Tab);

export const StyledBadge = withStyles(() => ({
  badge: {
    top: 1,
    right: 1,
    height: 14,
    minWidth: 12,
    padding: '0 3px 0 4px',
    fontSize: '0.42em',
    lineHeight: '1em',
    backgroundColor: '#D01801',
    color: 'white'
  }
}))(Badge);

export const RoundIconButton = withStyles(theme => ({
  root: {
    padding: 5,
    fontSize: 22,
    color: theme.palette.text.brand,
    borderRadius: '50%',
    boxShadow: '0px 4px 5px #00000033'
  }
}))(IconButton);

export default styles;
