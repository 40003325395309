import React, { Component } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import { Alert, AlertTitle } from '@material-ui/lab';

import {
  Grid,
  Button,
  Snackbar,
  TextField,
  Link,
  Typography
} from '@material-ui/core';

import { Facebook as FacebookIcon, Google as GoogleIcon } from '../../icons';

import { withFirebase } from '../Firebase';
import {
  ARTICLES,
  SIGN_UP,
  PASSWORD_FORGET,
  SIGN_IN,
  ONBOARDING
} from '../../constants/routes';
import { MEMBER } from '../../constants/roles';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(10),
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.primary,
    fontWeight: 500
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary
  },
  bio: {
    color: theme.palette.primary
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  signUpLink: {
    width: '60%'
  },
  forgotLink: {
    textAlign: 'right',
    width: '40%'
  }
});

const SignInPage = () => <SignInForm />;

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  openAlert: false
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;
    const { history, location, firebase } = this.props;

    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(({ user }) => firebase.user(user.uid).get())
      .then(snapshot => {
        this.setState({ ...INITIAL_STATE });
        const authUser = snapshot.data();

        if (authUser && !authUser.onboardingCompleted) {
          history.push(ONBOARDING);
          return;
        }

        if (location.state && location.state.pathname) {
          history.push(location.state.pathname);
          return;
        }

        history.push(ARTICLES);
      })
      .catch(error => {
        this.setState({ error, openAlert: true });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ openAlert: false });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes } = this.props;

    const { email, password, error, openAlert } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote} color="primary">
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  The sole purpose of Zonotho is to help people make better
                  financial decisions. “Make Finance simple”
                </Typography>
                <div className={classes.person}>
                  <Typography className={classes.name} variant="body1">
                    Thando Ncube
                  </Typography>
                  <Typography className={classes.bio} variant="body2">
                    Co-Founder
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={7} xs={12}>
            <div>
              {/* <div className={classes.contentHeader}>
                <IconButton onClick={this.handleBack}>
                  <ArrowBackIcon />
                </IconButton>
              </div> */}
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={this.onSubmit}>
                  <Typography className={classes.title} variant="h2">
                    Sign in
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    Sign in with social media
                  </Typography>
                  <Grid className={classes.socialButtons} container spacing={2}>
                    <Grid item className={classes.socialButton}>
                      <SignInGoogle />
                    </Grid>
                    <Grid item className={classes.socialButton}>
                      <SignInFacebook />
                    </Grid>
                  </Grid>
                  <Typography
                    align="center"
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1">
                    or login with email address
                  </Typography>
                  <TextField
                    className={classes.textField}
                    required
                    id="email"
                    label="Email Address"
                    name="email"
                    variant="outlined"
                    value={email}
                    fullWidth
                    onChange={this.onChange}
                  />
                  <TextField
                    className={classes.textField}
                    required
                    id="password"
                    label="Password"
                    name="password"
                    variant="outlined"
                    value={password}
                    type="password"
                    fullWidth
                    onChange={this.onChange}
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={isInvalid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained">
                    Sign in
                  </Button>

                  <Grid container spacing={2}>
                    <Grid item className={classes.signUpLink}>
                      <Typography color="textSecondary" variant="body1">
                        {` Don't have an account? `}
                        <Link component={RouterLink} to={SIGN_UP} variant="h6">
                          Sign up
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item className={classes.forgotLink}>
                      <Typography color="textSecondary" variant="body1">
                        <Link
                          component={RouterLink}
                          to={PASSWORD_FORGET}
                          variant="h6">
                          Forgot Password?
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          onClose={this.handleClose}
          autoHideDuration={3000}>
          <Alert variant="filled" severity="error" onClose={this.handleClose}>
            <AlertTitle>Error</AlertTitle>
            {error && error.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      openError: false,
      openSuccess: false,
      success: null
    };
  }

  onSubmit = event => {
    const { history, location, firebase } = this.props;
    firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        this.setState({
          error: null,
          openError: false,
          success: {
            message: 'Google login successful'
          },
          openSuccess: true
        });

        firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
            role: MEMBER
          },
          { merge: true }
        );

        return socialAuthUser.user.uid;
      })
      .then(uid => firebase.user(uid).get())
      .then(snapshot => {
        const authUser = snapshot.data();

        if (authUser && !authUser.onboardingCompleted) {
          history.push(ONBOARDING);
          return;
        }

        if (location.state && location.state.pathname) {
          history.push(location.state.pathname);
          return;
        }

        history.push(ARTICLES);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error, openError: true });
      });

    event.preventDefault();
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      openError: false,
      openSuccess: false
    });
  };

  render() {
    const { classes } = this.props;
    const { openError, error, openSuccess, success } = this.state;

    return (
      <div className={classes.socialButton}>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={this.onSubmit}
          className={classes.socialButton}>
          <GoogleIcon className={classes.socialIcon} />
          Login with Google
        </Button>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          onClose={this.handleClose}
          autoHideDuration={5000}>
          <Alert variant="filled" severity="error" onClose={this.handleClose}>
            <AlertTitle>Error</AlertTitle>
            {error && error.message}
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSuccess}
          onClose={this.handleClose}
          autoHideDuration={3000}>
          <Alert variant="filled" severity="success" onClose={this.handleClose}>
            {success && success.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      openError: false,
      openSuccess: false,
      success: null
    };
  }

  onSubmit = event => {
    const { history, location, firebase } = this.props;
    firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        this.setState({
          error: null,
          openError: false,
          success: {
            message: 'Facebook login successful'
          },
          openSuccess: true
        });

        firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
            role: MEMBER
          },
          { merge: true }
        );

        return socialAuthUser.user.uid;
      })
      .then(uid => firebase.user(uid).get())
      .then(snapshot => {
        const authUser = snapshot.data();

        if (authUser && !authUser.onboardingCompleted) {
          history.push(ONBOARDING);
          return;
        }

        if (location.state && location.state.pathname) {
          history.push(location.state.pathname);
          return;
        }

        history.push(ARTICLES);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error, openError: true });
      });

    event.preventDefault();
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      openError: false,
      openSuccess: false
    });
  };

  render() {
    const { classes } = this.props;
    const { openError, error, openSuccess, success } = this.state;

    return (
      <div className={classes.socialButton}>
        <Button
          size="large"
          variant="contained"
          onClick={this.onSubmit}
          className={classes.socialButton}>
          <FacebookIcon className={classes.socialIcon} />
          Login with Facebook
        </Button>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          onClose={this.handleClose}
          autoHideDuration={5000}>
          <Alert variant="filled" severity="error" onClose={this.handleClose}>
            <AlertTitle>Error</AlertTitle>
            {error && error.message}
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSuccess}
          onClose={this.handleClose}
          autoHideDuration={3000}>
          <Alert variant="filled" severity="success" onClose={this.handleClose}>
            {success && success.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const SignInLink = () => (
  <p>
    <Link to={SIGN_IN}>Already have an account? Sign In</Link>
  </p>
);

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  state
});

const SignInForm = compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps)
)(withStyles(styles)(SignInFormBase));

const SignInGoogle = compose(
  withRouter,
  withFirebase
)(withStyles(styles)(SignInGoogleBase));

const SignInFacebook = compose(
  withRouter,
  withFirebase
)(withStyles(styles)(SignInFacebookBase));

SignInFormBase.propTypes = {
  classes: PropTypes.object.isRequired
};

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook, SignInLink };
