import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { styles } from './TextField.style';

const TextField = ({
  classes,
  type,
  name,
  value,
  label,
  multiline,
  rows,
  onChange
}) => {
  return (
    <div className={classes.root}>
      <InputLabel
        className={classes.label}
        htmlFor={name}
        disableAnimation={true}>
        {label}
      </InputLabel>
      <InputBase
        className={classes.input}
        id={name}
        type={type}
        name={name}
        value={value}
        fullWidth={true}
        multiline={multiline}
        rows={rows}
        onChange={onChange}
      />
    </div>
  );
};

TextField.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.oneOf(['text', 'url']),
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

TextField.defaultProps = {
  type: 'text',
  multiline: false
};

export default withStyles(styles)(TextField);
