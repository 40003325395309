export const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2, 0)
  },
  pageTitle: {
    fontWeight: 400,
    letterSpacing: 0
  },
  noContentTitle: {
    marginTop: theme.spacing(2)
  },
  notificationList: {
    listStyle: 'none'
  },
  notificationItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.5)
    }
  }
});

export default styles;
