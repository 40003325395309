import React from 'react';
import { Articles } from '../Articles';

const Landing = () => (
  <div>
    <Articles />
  </div>
);

export default Landing;
