import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactPlayer from 'react-player/youtube';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
  Card,
  CardActionArea,
  CardActions,
  Typography,
  Grid,
  Avatar,
  CardMedia,
  IconButton,
  Popover,
  Box
} from '@material-ui/core';

import { AccessTime, Bookmark, ThumbUp } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';

import he from 'he';

import { withFirebase } from '../Firebase';
import { ARTICLES, AUTHOR } from '../../constants/routes';
import { authUserSelector } from '../Session/selectors';
import { MoreOptions, Share } from '../Article/components';
import { Delete } from '../PopoverMessages';
import { styles } from './ArticleItem.style';

const POPOVER_ANCHOR_ORIGIN = {
  vertical: 'center',
  horizontal: 'center'
};
const POPOVER_TRANSFORM_ORIGIN = {
  vertical: 'center',
  horizontal: 'center'
};
const DELETE_MESSAGE = 'Are you sure you want to delete this post?';
const TEMPERORY_EDIT_MESSAGE =
  'Edit functionality is coming soon. Would you like to delete instead?';

const ArticleItem = ({
  authUser,
  article,
  classes,
  history,
  isSaved,
  isLiked = false,
  isEditable,
  onSetSelectedAuthor,
  onLikeClick,
  onSaveClick,
  onDeleteClick,
  firebase
}) => {
  const [optionAnchorEl, setOptionAnchorEl] = useState(null);
  const [shouldShowDeleteMessage, showDeleteMessage] = useState(false);
  const [shouldShowEditMessage, showEditMessage] = useState(false);
  const articleItemRef = useRef();

  const { contributor, contributorRef } = article;
  const {
    firstName,
    lastName,
    username,
    memberStatus,
    occupation
  } = contributor;

  const handleOptionsClick = e => {
    e.preventDefault();
    setOptionAnchorEl(e.currentTarget);
  };

  const closeOptions = e => {
    e.preventDefault();
    setOptionAnchorEl(null);
  };

  const handleEditOptionClick = e => {
    showEditMessage(true);
    closeOptions(e);
  };

  const handleDeleteOptionClick = e => {
    showDeleteMessage(true);
    closeOptions(e);
  };

  const closeEditMessage = () => {
    showEditMessage(false);
  };

  const closeDeleteMessage = () => {
    showDeleteMessage(false);
  };

  const handleDeletePostClick = () => {
    if (showDeleteMessage) closeDeleteMessage();
    if (showEditMessage) closeEditMessage();
    onDeleteClick(article.uid);
  };

  const onAuthorClick = (authorData, authorId) => {
    const author = {
      ...authorData,
      uid: authorId
    };

    onSetSelectedAuthor(author);
    history.push(`${AUTHOR}/${authorId}`);
  };

  const getStrippedSummary = content => {
    const stripedHtml = content.replace(/<[^>]+>/g, '');
    const words = stripedHtml.split(' ');

    const maxWords = 25;

    let summary = words.join(' ');
    if (words.length > maxWords) {
      summary = `${words.slice(0, maxWords).join(' ')} ...`;
    }

    return summary;
  };

  const avatar = contributor.avatar || '/images/default-avatar.png';
  const fullName = firstName ? `${firstName} ${lastName}` : username;
  const authUserIsArticleAuthor = authUser && authUser.uid === contributorRef;
  const likes = article.likes ? article.likes.length : 0;
  const comments = article.comments ? Object.keys(article.comments).length : 0;

  let status = memberStatus || '';
  if (occupation) {
    status = status !== '' ? `${status} | ${occupation}` : `${occupation}`;
  }

  const readingTime = article.readingTime ? `${article.readingTime}min` : null;
  const tags = [];
  let summary = getStrippedSummary(article.content);

  if (article.description && article.description !== '') {
    summary = getStrippedSummary(article.description);
  }

  if (article.tags && article.tags.length > 0) {
    const maxTags = 5;
    article.tags.slice(0, maxTags).forEach(tag => {
      tags.push(tag.charAt(0).toUpperCase() + tag.substring(1).toLowerCase());
    });
  }

  const isTimestamp = article.created instanceof firebase.timeStamp;

  return (
    <Card ref={articleItemRef}>
      <Box className={classes.contentContainer}>
        {isTimestamp && (
          <Moment className={classes.postingTime} fromNow>
            {article.created.toDate()}
          </Moment>
        )}
        {article.type === 'video' && (
          <CardMedia className={classes.videoContainer}>
            <ReactPlayer
              className={classes.videoPlayer}
              url={article.content}
              controls
              width="320"
              height="240"
            />
          </CardMedia>
        )}
        <CardActionArea
          component={RouterLink}
          to={`${ARTICLES}/${article.uid}`}
          disableRipple={Boolean(optionAnchorEl)}
          disable={optionAnchorEl}>
          {article.banner && (
            <div className={classes.mediaContainer}>
              <CardMedia className={classes.imageContainer}>
                <img
                  alt="Product"
                  className={classes.image}
                  src={article.banner}
                />
              </CardMedia>
              <Box className={classes.mediaTopContainer}>
                <Grid container spacing={1} direction="row-reverse">
                  {readingTime && (
                    <Grid item>
                      <Typography className={classes.tagItem} component="span">
                        <AccessTime className={classes.readingTimeIcon} />
                        <Typography
                          className={classes.readingTimeValue}
                          component="span">
                          {readingTime}
                        </Typography>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box className={classes.mediaBottomContainer}>
                <Grid
                  container
                  spacing={1}
                  wrap="nowrap"
                  alignItems="flex-end"
                  justify="space-between">
                  {tags.length > 0 && (
                    <Grid item>
                      <Grid
                        className={classes.tagList}
                        container
                        component="ul"
                        spacing={1}>
                        {tags.map(tag => (
                          <Grid item component="li" key={tag}>
                            <Typography
                              className={classes.tagItem}
                              component="span">
                              {tag}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </div>
          )}
          {article.title && (
            <Grid container>
              <Grid item xs>
                <Typography align="left" gutterBottom variant="h5">
                  {he.decode(article.title)}
                </Typography>
              </Grid>
              {isEditable && (
                <Grid item>
                  <MoreOptions
                    size="small"
                    anchorEl={optionAnchorEl}
                    onOptionsClick={handleOptionsClick}
                    onOptionClose={closeOptions}
                    onEditClick={handleEditOptionClick}
                    onDeleteClick={handleDeleteOptionClick}
                  />
                </Grid>
              )}
            </Grid>
          )}
          <Grid container>
            <Grid item xs>
              <Typography align="left" variant="body2">
                {he.decode(summary)}
              </Typography>
            </Grid>
            {isEditable && !article.title && (
              <Grid item>
                <MoreOptions
                  size="small"
                  anchorEl={optionAnchorEl}
                  onOptionsClick={handleOptionsClick}
                  onOptionClose={closeOptions}
                  onEditClick={handleEditOptionClick}
                  onDeleteClick={handleDeleteOptionClick}
                />
              </Grid>
            )}
          </Grid>
        </CardActionArea>
        <CardActionArea
          className={classes.contributorContainer}
          component="div"
          disabled={authUserIsArticleAuthor}
          onClick={() => onAuthorClick(contributor, contributorRef)}>
          {contributor && (
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar alt="Avatar" className={classes.avatar} src={avatar} />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography variant="h5">{fullName}</Typography>
                <Typography variant="body1">{status}</Typography>
              </Grid>
            </Grid>
          )}
        </CardActionArea>
      </Box>
      <CardActions className={classes.actionsContainer}>
        <Grid container wrap="nowrap" spacing={2} justify="space-between">
          <Grid item>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <IconButton
                  className={classes.likeButton}
                  disabled={authUserIsArticleAuthor}
                  onClick={() => onLikeClick(article.uid)}>
                  <ThumbUp
                    className={clsx({
                      [classes.likeIcon]: true,
                      liked: isLiked
                    })}
                  />
                  <Typography className={classes.likeValue} component="span">
                    {likes}
                  </Typography>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton className={classes.commentButton} disableRipple>
                  <FontAwesomeIcon
                    className={classes.commentIcon}
                    icon={faComment}
                  />
                  <Typography className={classes.commentValue} component="span">
                    {comments}
                  </Typography>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Share article={article} />
            {!authUserIsArticleAuthor && (
              <span>
                &nbsp;|&nbsp;
                <IconButton
                  className={classes.favoriteBtn}
                  onClick={() => onSaveClick(article.uid)}>
                  {isSaved ? 'Saved' : 'Save'}
                  <Bookmark
                    className={clsx({
                      [classes.bookmarkIcon]: true,
                      saved: isSaved
                    })}
                  />
                </IconButton>
              </span>
            )}
          </Grid>
        </Grid>
      </CardActions>
      <Popover
        anchorEl={articleItemRef.current}
        anchorOrigin={POPOVER_ANCHOR_ORIGIN}
        transformOrigin={POPOVER_TRANSFORM_ORIGIN}
        open={shouldShowDeleteMessage}
        onClose={closeDeleteMessage}>
        <Delete
          message={DELETE_MESSAGE}
          onCancelClick={closeDeleteMessage}
          onDeleteClick={handleDeletePostClick}
        />
      </Popover>
      <Popover
        anchorEl={articleItemRef.current}
        anchorOrigin={POPOVER_ANCHOR_ORIGIN}
        transformOrigin={POPOVER_TRANSFORM_ORIGIN}
        open={shouldShowEditMessage}
        onClose={closeEditMessage}>
        <Delete
          message={TEMPERORY_EDIT_MESSAGE}
          onCancelClick={closeEditMessage}
          onDeleteClick={handleDeletePostClick}
        />
      </Popover>
    </Card>
  );
};

ArticleItem.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  article: PropTypes.object.isRequired,
  classes: PropTypes.object,
  history: PropTypes.object,
  isSaved: PropTypes.bool,
  isLiked: PropTypes.bool,
  isEditable: PropTypes.bool,
  onSetSelectedAuthor: PropTypes.func,
  onLikeClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  onDeleteClick: PropTypes.func
};

ArticleItem.defaultProps = {
  isLiked: false,
  isEditable: false
};

const mapStateToProps = state => ({
  authUser: authUserSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetArticlesCategory: category =>
    dispatch({ type: 'ARTICLES_CATEGORY_SET', category }),
  onSetSelectedAuthor: author => dispatch({ type: 'AUTHOR_SET', author })
});

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(ArticleItem));
