export const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2)
  },
  progress: {
    display: 'flex',
    justifyContent: 'center'
  },
  headerGrid: {
    marginBottom: theme.spacing(2.5)
  },
  usersGrid: {
    marginBottom: theme.spacing(3)
  },
  header: {
    padding: theme.spacing(0, 2)
  },
  pageTitle: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 400,
    letterSpacing: 0
  },
  pageSubtitle: {
    fontSize: 14,
    lineHeight: 1.4286
  }
});

export default styles;
