import React, { Component } from 'react';
import { Grid, Typography, Paper, Chip } from '@material-ui/core';

import { changeUserCoins } from '../../common/Helpers';

import { SEARCH, ARTICLES } from '../../constants/routes';
import ArticleList from '../Articles/ArticleList';
import { ArticleTypeFilterCard } from '../common';

class ExploreBase extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { selectedTags } = this.props;

    if (!selectedTags || selectedTags.length === 0) {
      this.props.history.replace(SEARCH);
    }
  }

  switchFilter = e => {
    this.props.setFilter(e.target.value);
  };

  onLikeClick = articleId => {
    const {
      authUser,
      searchData: { articles }
    } = this.props;
    const article = articles.find(a => a.uid === articleId);

    if (!authUser) {
      this.props.showSighInDialog(
        { pathname: `${ARTICLES}/${article.uid}` },
        'like the post'
      );
      return;
    }

    if (!article.likes || article.likes.length === 0) {
      const newLikes = [authUser.uid];
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.increment.bind(this, article.contributorRef)
      );
      return;
    }

    if (article.likes.includes(authUser.uid)) {
      const newLikes = article.likes.filter(l => l !== authUser.uid);
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.decrement.bind(this, article.contributorRef)
      );
      return;
    }

    const newLikes = [...article.likes, authUser.uid];
    this.updateArticleLikes(
      articleId,
      newLikes,
      changeUserCoins.increment.bind(this, article.contributorRef)
    );
  };

  updateArticleLikes(articleId, likes, doUpdateUserCoins) {
    const {
      firebase,
      searchData: { articles },
      setDataArticles
    } = this.props;

    firebase
      .article(articleId)
      .set(
        {
          likes,
          likesCount: likes.length
        },
        { merge: true }
      )
      .then(() => {
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === articleId);
        if (articleIndex !== -1) {
          newArticles[articleIndex].likes = [...likes];
        }
        doUpdateUserCoins();
        setDataArticles(newArticles);
      })
      .catch(error => {
        console.log('error :', error);
      });
  }

  onSaveClick = articleId => {
    const { authUser, showSighInDialog } = this.props;

    if (!authUser) {
      showSighInDialog(
        { pathname: `${ARTICLES}/${articleId}` },
        'save the post'
      );
      return;
    }

    if (!authUser.savedArticles) {
      this.updateSavedArticles([articleId]);
      return;
    }

    const isSaved = authUser.savedArticles.includes(articleId);
    if (isSaved) {
      const articles = authUser.savedArticles.filter(a => a !== articleId);
      this.updateSavedArticles(articles);
      return;
    }

    const articles = [...authUser.savedArticles, articleId];
    this.updateSavedArticles(articles);
  };

  updateSavedArticles = articles => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const updatedUser = {
      ...authUser,
      savedArticles: [...articles]
    };

    firebase
      .user(authUser.uid)
      .set(
        {
          savedArticles: articles
        },
        { merge: true }
      )
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  render() {
    const { classes, authUser, searchData, selectedTags, filter } = this.props;
    let result;

    if (selectedTags && selectedTags.length > 0) {
      const selectedTagNames = selectedTags
        ? selectedTags.map(({ name }) => name)
        : [];

      const filteredByTags = searchData.articles.filter(article => {
        const tags = article.tags || [];
        return tags.some(tag => selectedTagNames.includes(tag));
      });

      result =
        filter === 'all'
          ? [...filteredByTags]
          : filteredByTags.filter(a => a.type === filter);
    }

    const userSavedArticlesIds = (authUser && authUser.savedArticles) || [];

    return (
      <div className={classes.root}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography className={classes.pageTitle} variant="h1">
              Explore
            </Typography>
          </Grid>
          <Grid item>
            <Paper className={classes.tagsContainer}>
              {selectedTags.map(({ uid, name }) => {
                const capitalizeName = name
                  .toLowerCase()
                  .split(' ')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ');

                return (
                  <Chip
                    className={classes.tag}
                    key={uid}
                    label={capitalizeName}
                    size="small"
                  />
                );
              })}
            </Paper>
          </Grid>
          <Grid item>
            <ArticleTypeFilterCard
              value={filter}
              onChange={this.switchFilter}
            />
          </Grid>
          {result && result.length === 0 && (
            <Grid item>
              <Typography align="center" variant="body1">
                There are not any post in result
              </Typography>
            </Grid>
          )}
          {result && result.length > 0 && (
            <Grid item>
              <ArticleList
                userId={authUser ? authUser.uid : null}
                articles={result}
                savedArticlesIds={userSavedArticlesIds}
                onLikeClick={this.onLikeClick}
                onSaveClick={this.onSaveClick}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default ExploreBase;
