import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  Snackbar,
  LinearProgress
} from '@material-ui/core';

import { getBase64Strings } from 'exif-rotate-js/lib';

import { Alert, AlertTitle } from '@material-ui/lab';

import { withFirebase } from '../../Firebase';

const styles = theme => ({
  root: {},
  contentContainer: {},
  content: {
    paddingTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(2)
    }
  },
  contentHeader: {
    display: 'flex',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  details: {
    textAlign: 'center',
    width: '100%'
  },
  avatarCard: {
    justifyContent: 'center',
    display: 'flex'
  },
  avatar: {
    height: 195,
    width: 195
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  loader: {
    textAlign: 'center',
    width: '100%'
  }
});

const INITIAL_STATE = {
  avatar: '/images/default-avatar.png',
  error: null,
  openAlert: false,
  image: null,
  progress: 1,
  loading: false
};

class AvatarBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.setState({
      avatar: this.props.authUser.avatar
        ? this.props.authUser.avatar
        : '/images/default-avatar.png'
    });
  }

  handleChange = e => {
    if (e.target.files[0]) {
      this.setState(() => ({ loading: true }));

      const image = e.target.files[0];
      const imageName = `${this.props.authUser.uid}`;

      if (
        !image.type.includes('jpeg') &&
        !image.type.includes('jpg') &&
        !image.type.includes('png') &&
        !image.type.includes('gif')
      ) {
        const error = { message: 'Only images are allowed' };

        this.setState({ error, openAlert: true });
      } else {
        getBase64Strings(e.target.files, { maxSize: 720 })
          .then(result => {
            const uploadTask = this.props.firebase.storage
              .ref(`avatars/${imageName}`)
              .putString(result[0], 'data_url', { contentType: image.type });

            uploadTask.on(
              'state_changed',
              snapshot => {
                // progress function ...
                const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                this.setState({ progress });
              },
              error => {
                // Error function ...
                console.log(error);
              },
              () => {
                // complete function ...
                this.props.firebase.storage
                  .ref('avatars')
                  .child(imageName)
                  .getDownloadURL()
                  .then(url => {
                    this.setState({
                      avatar: url,
                      loading: false,
                      progress: 1
                    });

                    this.props.firebase
                      .user(this.props.authUser.uid)
                      .set(
                        {
                          avatar: url
                        },
                        { merge: true }
                      )
                      .then(() => {
                        this.props.authUser.avatar = url;

                        localStorage.setItem(
                          'authUser',
                          JSON.stringify(this.props.authUser)
                        );
                        this.props.onSetAuthUser(this.props.authUser);
                      })
                      .catch(error => {
                        console.log('error :', error);
                        this.setState({ error, openAlert: true });
                      });
                  });
              }
            );
          })
          .catch(error => {
            console.log('error :', error);
            this.setState({ error, openAlert: true });
          });
      }
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      openAlert: false
    });
  };

  render() {
    const { avatar, error, openAlert, progress, loading } = this.state;
    const { classes } = this.props;

    return (
      <Grid className={classes.content} item lg={2} xs={12}>
        <Card>
          <CardHeader title="Avatar" />
          <Divider />

          {loading && (
            <div className={classes.loader}>
              <LinearProgress variant="determinate" value={progress} />
            </div>
          )}

          <CardContent className={classes.avatarCard}>
            <Avatar className={classes.avatar} src={avatar} />
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              className={classes.uploadButton}
              color="primary"
              fullWidth
              variant="text"
              component="label">
              Upload Image
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={this.handleChange}
                accept="image/*"
              />
            </Button>
          </CardActions>
        </Card>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          onClose={this.handleClose}
          autoHideDuration={5000}>
          <Alert variant="filled" severity="error" onClose={this.handleClose}>
            <AlertTitle>Error</AlertTitle>
            {error && error.message}
          </Alert>
        </Snackbar>
      </Grid>
    );
  }
}

AvatarBase.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser })
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(withStyles(styles)(AvatarBase));
