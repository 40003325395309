import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import {
  ARTICLES,
  TRENDS,
  POPULAR_USERS,
  SIGN_IN,
  NOTIFICATIONS,
  NEW_POST
} from '../../constants/routes';

import { AppBar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { LibraryBooks, Notifications, Add, People } from '@material-ui/icons';

import { Icon } from '@iconify/react';
import fireIcon from '@iconify/icons-mdi/fire';

import {
  styles,
  StyledTabs,
  StyledTab,
  StyledBadge,
  RoundIconButton
} from './BottomBar.style';
import { newNotificationsAmount } from '../../components/Notifications/selectors';

const TAB_VALUES = [ARTICLES, NOTIFICATIONS, NEW_POST, TRENDS, POPULAR_USERS];
const INITIAL_STATE = {};

class BottomBar extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  render() {
    const { classes, location, authUser, newNotificationsAmount } = this.props;

    const selectedTab = TAB_VALUES.includes(location.pathname)
      ? location.pathname
      : false;

    return (
      <AppBar className={classes.root} component="nav">
        <StyledTabs
          className={classes.tabs}
          value={selectedTab}
          variant="fullWidth"
          aria-label="page tabs">
          <StyledTab
            className={classes.tab}
            icon={<LibraryBooks />}
            component={RouterLink}
            to={ARTICLES}
            value={ARTICLES}
          />
          <StyledTab
            className={classes.tab}
            icon={<Icon icon={fireIcon} />}
            component={RouterLink}
            to={TRENDS}
            value={TRENDS}
          />
          <StyledTab
            className={classes.tab}
            icon={
              <RoundIconButton>
                <Add />
              </RoundIconButton>
            }
            component={RouterLink}
            to={!authUser ? SIGN_IN : NEW_POST}
            value={!authUser ? SIGN_IN : NEW_POST}
            disableRipple={true}
          />
          <StyledTab
            className={classes.tab}
            icon={<People />}
            component={RouterLink}
            to={POPULAR_USERS}
            value={POPULAR_USERS}
          />
          <StyledTab
            className={classes.tab}
            icon={
              <StyledBadge badgeContent={newNotificationsAmount}>
                <Notifications />
              </StyledBadge>
            }
            component={RouterLink}
            to={!authUser ? SIGN_IN : NOTIFICATIONS}
            value={!authUser ? SIGN_IN : NOTIFICATIONS}
          />
        </StyledTabs>
        <Typography className={classes.spacer} variant="body1">
          &nbsp;
        </Typography>
      </AppBar>
    );
  }
}

BottomBar.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  newNotificationsAmount: newNotificationsAmount(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(withStyles(styles)(BottomBar));
