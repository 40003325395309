import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import ArticleItem from './ArticleItem';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(40),
      marginRight: theme.spacing(40)
    }
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  firstArticle: {
    marginBottom: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));

const ArticleList = ({
  userId,
  articles,
  savedArticlesIds,
  isEditable = false,
  onLikeClick,
  onSaveClick,
  onDeleteClick
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container spacing={3}>
          {articles.map(article => {
            const isSaved = savedArticlesIds.includes(article.uid);
            let isLiked = false;

            if (userId && article.likes && article.likes.length > 0) {
              isLiked = article.likes.includes(userId);
            }
            return (
              <Grid item key={article.uid} lg={4} md={12} xs={12}>
                <ArticleItem
                  key={article.uid}
                  article={article}
                  isSaved={isSaved}
                  isLiked={isLiked}
                  isEditable={isEditable}
                  onLikeClick={onLikeClick}
                  onSaveClick={onSaveClick}
                  onDeleteClick={onDeleteClick}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default ArticleList;
