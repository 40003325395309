import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Snackbar,
  Paper
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import AvatarBase from './Avatar';
import { LANDING } from '../../constants/routes';

const styles = theme => ({
  root: {},
  contentContainer: {},
  content: {
    height: '100%',
    padding: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  updateButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  signOut: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  statistic: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.text.brand
  }
});

const genderMap = [
  {
    value: 'nonSelected',
    label: 'Select a gender'
  },
  {
    value: 'male',
    label: 'Male'
  },
  {
    value: 'female',
    label: 'Female'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  age: '',
  occupation: '',
  gender: '',
  description: '',
  error: null,
  openError: false,
  openSuccess: false,
  success: null
};

const AccountPage = () => (
  <div>
    <Grid container>
      <AvatarBase />
      <ProfileForm />
    </Grid>
  </div>
);

class ProfileFormBase extends Component {
  constructor(props) {
    super(props);

    this.signOut = this.signOut.bind(this);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.setState({
      firstName: this.props.authUser.firstName,
      lastName: this.props.authUser.lastName,
      age: this.props.authUser.age ? this.props.authUser.age : '',
      occupation: this.props.authUser.occupation
        ? this.props.authUser.occupation
        : '',
      gender: this.props.authUser.gender ? this.props.authUser.gender : '',
      description: this.props.authUser.description
        ? this.props.authUser.description
        : ''
    });
  }

  onSubmit = event => {
    this.updateUser();

    event.preventDefault();
  };

  updateUser() {
    const {
      firstName,
      lastName,
      age,
      occupation,
      gender,
      description
    } = this.state;
    const { authUser } = this.props;

    this.props.firebase
      .user(this.props.authUser.uid)
      .set(
        {
          firstName,
          lastName,
          age,
          occupation,
          gender,
          description
        },
        { merge: true }
      )
      .then(() => {
        authUser.firstName = firstName;
        authUser.lastName = lastName;
        authUser.age = age;
        authUser.occupation = occupation;
        authUser.gender = gender;
        authUser.description = description;

        localStorage.setItem('authUser', JSON.stringify(authUser));
        this.props.onSetAuthUser(authUser);

        this.setState({
          success: {
            message: 'Account updated successfully'
          },
          openSuccess: true
        });
      })
      .catch(error => {
        console.log('error :', error);
        this.setState({ error, openError: true });
      });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      openError: false,
      openSuccess: false
    });
  };

  signOut = event => {
    event.preventDefault();

    this.props.firebase
      .doSignOut()
      .then(() => {
        if (typeof this.props.onClose === 'function') {
          if (this.props.onClose()) {
            this.props.onClose();
          }
        }

        this.props.history.push(LANDING);
      })
      .catch(error => {
        console.log('error :', error);
      });
  };

  render() {
    const { classes, authUser } = this.props;
    const {
      firstName,
      lastName,
      age,
      occupation,
      gender,
      description,
      openError,
      error,
      openSuccess,
      success
    } = this.state;

    const isInvalid =
      firstName === '' ||
      lastName === '' ||
      age === '' ||
      occupation === '' ||
      gender === '' ||
      gender === 'nonSelected';

    // const disableEmail = !this.props.authUser.activeSignInMethods.includes(
    //   'password'
    // );

    return (
      <Grid className={classes.content} item lg={10} xs={12}>
        <Card>
          <form autoComplete="off" noValidate onSubmit={this.onSubmit}>
            <CardHeader title="Account Details" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="First name"
                    margin="dense"
                    name="firstName"
                    onChange={this.onChange}
                    required
                    value={firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Last name"
                    margin="dense"
                    name="lastName"
                    onChange={this.onChange}
                    required
                    value={lastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    margin="dense"
                    name="email"
                    onChange={this.onChange}
                    required
                    value={authUser.email}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Age"
                    margin="dense"
                    name="age"
                    onChange={this.onChange}
                    required
                    value={age}
                    variant="outlined"
                    type="number"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Occupation"
                    margin="dense"
                    name="occupation"
                    onChange={this.onChange}
                    required
                    value={occupation}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Gender"
                    margin="dense"
                    name="gender"
                    onChange={this.onChange}
                    required
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={gender}
                    variant="outlined">
                    {genderMap.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    label="About Me"
                    margin="dense"
                    name="description"
                    onChange={this.onChange}
                    required
                    value={description}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isInvalid}
                className={classes.updateButton}>
                Update details
              </Button>
            </CardActions>
          </form>
        </Card>

        <Grid item xs>
          <Paper className={classes.statistic}>
            <Button
              color="primary"
              fullWidth
              variant="text"
              component="label"
              onClick={this.signOut}>
              <InputIcon className={classes.signOut} />
              Sign out
            </Button>
          </Paper>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          onClose={this.handleClose}
          autoHideDuration={3000}>
          <Alert variant="filled" severity="error" onClose={this.handleClose}>
            <AlertTitle>Error</AlertTitle>
            {error && error.message}
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSuccess}
          onClose={this.handleClose}
          autoHideDuration={3000}>
          <Alert variant="filled" severity="success" onClose={this.handleClose}>
            {success && success.message}
          </Alert>
        </Snackbar>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser })
});

const condition = authUser => !!authUser;

const ProfileForm = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(withStyles(styles)(ProfileFormBase));

ProfileFormBase.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withEmailVerification,
  withAuthorization(condition)
)(AccountPage);

export { ProfileForm };
