import { TextField, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const styles = theme => ({
  avatar: {
    width: 33,
    height: 33
  },
  name: {
    fontSize: 12,
    lineHeight: 1.3334,
    fontWeight: 700,
    letterSpacing: 0.4,
    color: 'inherit',
    '& a': {
      color: 'inherit'
    }
  },
  period: {
    fontFamily: 'Roboto',
    fontSize: 8,
    lineHeight: 1.25,
    letterSpacing: 0.27,
    color: 'inherit'
  },
  text: {
    padding: 0,
    fontSize: 14,
    lineHeight: 1.3572,
    letterSpacing: 0.46,
    color: 'inherit'
  },
  likeButton: {
    padding: 0,
    fontSize: 12,
    color: 'inherit',
    borderRadius: 0
  },
  likeIcon: {
    fontSize: '1.4em',
    '&.liked': {
      color: theme.palette.text.brand
    }
  },
  likeValue: {
    marginLeft: theme.spacing(0.5),
    fontSize: 'inherit',
    lineHeight: 1.3334,
    letterSpacing: '0.4px',
    color: 'inherit'
  },
  editorContainer: {
    '& .MuiPopover-paper': {
      maxWidth: 240,
      padding: theme.spacing(1),
      color: 'inherit'
    }
  },
  cancelBtn: {
    backgroundColor: '#D5D5D5'
  },
  updateBtn: {
    backgroundColor: theme.palette.background.brand
  }
});

export const EditorTextArea = withStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiOutlinedInput-multiline': {
      padding: theme.spacing(1.5, 1.75),
      fontSize: 14,
      lineHeight: '1.3333em',
      color: theme.palette.text.secondary
    }
  }
}))(TextField);

export const ActionButton = withStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 1),
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 1.07,
    color: theme.palette.white
  },
  contained: {
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}))(Button);

export default styles;
