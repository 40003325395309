import { withStyles } from '@material-ui/styles';
import { TextField, Button } from '@material-ui/core';

export const styles = theme => ({
  root: {
    color: theme.palette.text.secondary
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 18,
    lineHeight: '1.3333em',
    letterSpacing: 0.6,
    color: 'inherit'
  },
  subtitle: {
    fontSize: 12,
    lineHeight: '1.3333em',
    letterSpacing: 0.4,
    color: 'inherit'
  },
  emptyCommentMessage: {
    padding: theme.spacing(2, 0, 3),
    fontSize: 14,
    lineHeight: 1.2,
    color: 'inherit'
  },
  cancelBtn: {
    backgroundColor: '#D5D5D5'
  },
  postBtn: {
    backgroundColor: theme.palette.background.brand
  }
});

export const NewCommentArea = withStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiOutlinedInput-multiline': {
      padding: theme.spacing(1.5, 1.75),
      fontSize: 12,
      lineHeight: '1.3333em',
      color: 'inherit'
    }
  }
}))(TextField);

export const ActionButton = withStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 1),
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 1.07,
    color: theme.palette.white
  },
  contained: {
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}))(Button);

export default styles;
