import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import {
  LANDING,
  ACCOUNT,
  PROFILE,
  AUTHOR_DETAILS,
  SETTINGS,
  ADMIN,
  PASSWORD_FORGET,
  SIGN_IN,
  SIGN_UP,
  ARTICLES,
  ARTICLES_DETAILS,
  NOTIFICATIONS,
  NEW_POST,
  TRENDS,
  POPULAR_USERS,
  SEARCH,
  EXPLORE,
  FOLLOWERS,
  FOLLOWING,
  VERIFY_EMAIL,
  ONBOARDING
} from '../../constants/routes';
import RouteWithLayout from '../../common/RouteWithLayout';
import {
  Default as DefaultLayout,
  Main as MainLayout,
  Minimal as MinimalLayout
} from '../../layouts';

import AccountPage from '../Account';
import AdminPage from '../Admin';
import LandingPage from '../Landing';
import PasswordForgetPage from '../PasswordForget';
import SignInPage from '../SignIn';
import SignUpPage from '../SignUp';
import SettingsPage from '../Settings';
import { Articles } from '../Articles';
import ArticlePage from '../Article';
import VerifyEmailPage from '../VerifyEmail';
import ProfilePage from '../Profile';
import AuthorPage from '../Author';
import Onboarding from '../Onboarding';
import NotificationPage from '../Notifications';
import NewPostPage from '../NewPost';
import { FollowersPage, FollowingPage } from '../Follow';
import TrendsPage from '../Trends';
import PopularUsersPage from '../PopularUsers';
import SearchPage from '../Search';
import ExplorePage from '../Explore';

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={LandingPage}
        exact
        layout={DefaultLayout}
        path={LANDING}
      />
      <RouteWithLayout
        component={AccountPage}
        exact
        layout={MainLayout}
        path={ACCOUNT}
      />
      <RouteWithLayout
        component={ProfilePage}
        exact
        layout={MainLayout}
        path={PROFILE}
      />
      <RouteWithLayout
        component={SearchPage}
        exact
        layout={DefaultLayout}
        path={SEARCH}
      />
      <RouteWithLayout
        component={ExplorePage}
        exact
        layout={DefaultLayout}
        path={EXPLORE}
      />
      <RouteWithLayout
        component={AuthorPage}
        exact
        layout={DefaultLayout}
        path={AUTHOR_DETAILS}
      />
      <RouteWithLayout
        component={SettingsPage}
        exact
        layout={MainLayout}
        path={SETTINGS}
      />
      <RouteWithLayout
        component={AdminPage}
        exact
        layout={MainLayout}
        path={ADMIN}
      />
      <RouteWithLayout
        component={PasswordForgetPage}
        exact
        layout={MinimalLayout}
        path={PASSWORD_FORGET}
      />
      <RouteWithLayout
        component={SignInPage}
        exact
        layout={MinimalLayout}
        path={SIGN_IN}
      />
      <RouteWithLayout
        component={SignUpPage}
        exact
        layout={MinimalLayout}
        path={SIGN_UP}
      />
      <RouteWithLayout
        component={Articles}
        exact
        layout={DefaultLayout}
        path={ARTICLES}
      />
      <RouteWithLayout
        component={ArticlePage}
        exact
        layout={DefaultLayout}
        path={ARTICLES_DETAILS}
      />
      <RouteWithLayout
        component={NotificationPage}
        exact
        layout={DefaultLayout}
        path={NOTIFICATIONS}
      />
      <RouteWithLayout
        component={NewPostPage}
        exact
        layout={DefaultLayout}
        path={NEW_POST}
      />
      <RouteWithLayout
        component={TrendsPage}
        exact
        layout={DefaultLayout}
        path={TRENDS}
      />
      <RouteWithLayout
        component={PopularUsersPage}
        exact
        layout={DefaultLayout}
        path={POPULAR_USERS}
      />
      <RouteWithLayout
        component={FollowersPage}
        exact
        layout={DefaultLayout}
        path={FOLLOWERS}
      />
      <RouteWithLayout
        component={FollowingPage}
        exact
        layout={DefaultLayout}
        path={FOLLOWING}
      />
      <RouteWithLayout
        component={VerifyEmailPage}
        exact
        layout={DefaultLayout}
        path={VERIFY_EMAIL}
      />
      <Route
        render={matchProps => <Onboarding {...matchProps} />}
        exact
        path={ONBOARDING}
      />
      {/* <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      /> */}
      <Redirect to={LANDING} />
      {/* <Redirect to="/not-found" /> */}
    </Switch>
  );
};

export default Routes;
