export const styles = theme => ({
  '@global': {
    p: {
      paddingBottom: theme.spacing(3)
    },
    h2: {
      fontSize: 18,
      lineHeight: '20px',
      paddingBottom: theme.spacing(3)
    },
    a: {
      color: theme.palette.primary.main
    },
    img: {
      width: '100%'
    },
    ul: {
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5)
    },
    ol: {
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5)
    }
  },
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3)
    }
  },
  pageContent: {},
  contributorContainer: {
    width: 'auto',
    padding: theme.spacing(2, 1)
  },
  postingTime: {
    fontFamily: 'Roboto',
    fontSize: 10,
    lineHeight: 1.25,
    letterSpacing: 0.27,
    color: 'inherit'
  },
  videoContainer: {},
  videoPlayer: {
    height: 240,
    '& iframe': {
      display: 'block'
    }
  },
  articleContentContainer: {
    padding: theme.spacing(1),
    width: '100%'
  },
  articleHeaderLikeItem: {
    marginLeft: 'auto'
  },
  articleHeaderLikeButton: {
    margin: theme.spacing(-1),
    padding: theme.spacing(1),
    color: theme.palette.text.primary
  },
  articleHeaderLikeIcon: {
    fontSize: 15
  },
  articleHeaderLikeValue: {
    marginLeft: '3px',
    color: 'inherit'
  },
  articleHeaderLikeMessage: {
    width: 43,
    marginLeft: theme.spacing(1),
    fontSize: 8,
    lineHeight: '10px',
    color: theme.palette.text.secondary,
    textAlign: 'left'
  },
  articleTitle: {
    margin: theme.spacing(1, 0, 2),
    width: '100%'
  },
  articleContent: {
    width: '100%'
  },
  videoDescription: {
    padding: 0,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '28px',
    color: theme.palette.text.primary
  },
  articleBody: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '28px',
    color: theme.palette.text.primary
  },
  commentsContainer: {
    padding: theme.spacing(3, 1)
  },
  successMessage: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 2)
  },
  successMessageContent: {
    marginBottom: theme.spacing(7),
    padding: 0
  },
  successMessageTitle: {
    padding: 0,
    fontSize: 12,
    lineHeight: 1.3334,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  successMessageActions: {
    justifyContent: 'center',
    padding: 0
  },
  addPostBtn: {
    fontSize: 12,
    lineHeight: 1.3334,
    color: '#ffffff',
    backgroundColor: theme.palette.background.brand
  }
});

export default styles;
