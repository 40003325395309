export const styles = theme => ({
  actionsContainer: {
    borderBottom: `1px solid ${theme.palette.border}`,
    color: theme.palette.text.secondary
  },
  likeButton: {
    padding: 0,
    fontSize: 12,
    color: 'inherit',
    borderRadius: 0
  },
  likeIcon: {
    fontSize: '1.75em',
    '&.liked': {
      color: theme.palette.text.brand
    }
  },
  likeValue: {
    marginLeft: theme.spacing(0.5),
    fontSize: 13,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: 'inherit'
  },
  commentButton: {
    padding: 0,
    fontSize: 12,
    color: 'inherit',
    borderRadius: 0
  },
  commentIcon: {
    fontSize: 21
  },
  commentValue: {
    marginLeft: theme.spacing(0.5),
    fontSize: 13,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: 'inherit'
  },
  favoriteBtn: {
    padding: 0,
    fontSize: 12,
    lineHeight: '16px',
    color: 'inherit',
    textTransform: 'uppercase',
    borderRadius: 0
  },
  bookmarkIcon: {
    fontSize: '1.75em',
    '&.saved': {
      color: theme.palette.text.brand
    }
  }
});

export default styles;
