import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import { withFirebase } from '../Firebase';

import { authUserSelector } from '../Session/selectors';

import TrendsBase from './Trends';
import { styles } from './Trends.style';

const mapStateToProps = state => ({
  authUser: authUserSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
  showSighInDialog: (locationState, message) =>
    dispatch({ type: 'SIGN_IN_DIALOG_VISIBLE', locationState, message })
});

const TrendsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(withStyles(styles)(TrendsBase));

export default TrendsPage;
