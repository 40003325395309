import React from 'react';
import { withStyles } from '@material-ui/styles';

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';

import { styles } from './Benefits.style';

const Benefits = ({ classes, name }) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.greeting}>Nicely done!</Typography>
      <Typography className={classes.welcome}>
        Welcome to Zonotho,
        <br />
        {`${name}`}
      </Typography>
      <Typography className={classes.listTitle}>
        Here’s what you’ll get from your
        <br />
        Zonotho account…
      </Typography>
      <List className={classes.list} disablePadding>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listIcon}>
            <FontAwesomeIcon icon={faCoins} />
          </ListItemIcon>
          <ListItemText
            className={classes.listText}
            primary="Unlimited Finance Related Content"
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listIcon}>
            <FingerprintIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.listText}
            primary="Customised recommendations for you"
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listIcon}>
            <RoomServiceIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.listText}
            primary="Your own digital personal finance assistant"
          />
        </ListItem>
      </List>
    </div>
  );
};

export default withStyles(styles)(Benefits);
