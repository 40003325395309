import { withStyles } from '@material-ui/styles';
import { Radio, Button } from '@material-ui/core';

export const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2, 0)
  },
  pageTitle: {
    fontWeight: 400
  },
  form: {
    padding: theme.spacing(4, 1.5, 2)
  },
  typeGrid: {
    marginBottom: theme.spacing(3)
  },
  fieldGrid: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2)
    }
  },
  typeContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  typeLabel: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.6667,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    letterSpacing: 1.88,
    '&.Mui-focused': {
      color: theme.palette.text.secondary
    }
  },
  typeList: {
    display: 'flex',
    flexDirection: 'row'
  },
  typeItem: {
    margin: 0,
    '&:not(:last-child)': {
      marginRight: theme.spacing(3)
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
      lineHeight: 1.3334,
      color: theme.palette.text.secondary,
      letterSpacing: 0.4
    }
  },
  photoLabel: {
    display: 'inline-block',
    marginBottom: theme.spacing(1),
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.3334,
    color: theme.palette.text.secondary,
    letterSpacing: 1.88
  },
  photoContainer: {
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    '&.Mui-disabled': {
      opacity: '0.2'
    }
  },
  imageLoader: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    justifyContent: 'center',
    alignItems: 'center'
  },
  photoImage: {
    maxHeight: 200
  },
  photoUploadButton: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.3334,
    letterSpacing: 1.88,
    color: 'inherit',
    textTransform: 'none'
  },
  photoAdditional: {
    fontSize: 9,
    lineHeight: 2,
    color: theme.palette.text.secondary,
    opacity: 0.8,
    '& a': {
      color: theme.palette.text.brand
    }
  },
  cancelBtn: {
    backgroundColor: '#D5D5D5'
  },
  postBtn: {
    backgroundColor: theme.palette.background.brand
  },
  successMessage: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 2)
  },
  successMessageContent: {
    marginBottom: theme.spacing(7),
    padding: 0
  },
  successMessageTitle: {
    fontSize: 12,
    lineHeight: 1.3334,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  successMessageActions: {
    justifyContent: 'center',
    padding: 0
  },
  viewPostBtn: {
    fontSize: 12,
    lineHeight: 1.3334,
    color: '#ffffff',
    backgroundColor: theme.palette.background.brand
  }
});

export const StyledRadio = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    padding: 0,
    fontSize: 20,
    '&.MuiIconButton-colorSecondary.Mui-checked': {
      color: '#008265'
    }
  }
}))(Radio);

export const ActionButton = withStyles(theme => ({
  root: {
    padding: theme.spacing(0.5, 1),
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 1.07,
    color: theme.palette.white
  },
  contained: {
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}))(Button);

export default styles;
