import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/styles';
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Grid
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import shareIcon from '@iconify/icons-mdi/share';

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon
} from 'react-share';

const styles = () => ({
  shareItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 6px',
    fontSize: 10,
    lineHeight: '1em',
    borderRadius: '16px',
    backgroundColor: '#ffffff'
  },
  shareIcon: {
    fontSize: '1.75em'
  },
  shareButton: {
    padding: 0,
    fontSize: 12,
    lineHeight: '16px',
    color: 'inherit',
    textTransform: 'uppercase',
    borderRadius: 0
  }
});

const INITIAL_STATE = {
  shareDialog: {
    message: '',
    isShown: false
  }
};

class Share extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  getStrippedSummary = content => {
    const stripedHtml = content.replace(/<[^>]+>/g, '');
    const words = stripedHtml.split(' ');

    const maxWords = 25;

    let summary = words.join(' ');
    if (words.length > maxWords) {
      summary = `${words.slice(0, maxWords).join(' ')} ...`;
    }

    return summary;
  };

  shareDialogShow = state => {
    this.setState({
      shareDialog: {
        isShown: state
      }
    });
  };

  render() {
    const { classes, article } = this.props;
    const { shareDialog } = this.state;
    const { uid, banner } = article;

    const title = article.title || this.getStrippedSummary(article.content);
    const imageUrl = banner || '';
    const type = article.type || 'article';

    const url = `${window.location.origin}/articles/${uid}`;

    return (
      <span>
        <Helmet>
          <title>{title}</title>
          <meta property="og:image" content={imageUrl} />
          <meta property="og:image:secure_url" content={imageUrl} />
        </Helmet>

        <IconButton
          className={classes.shareButton}
          onClick={() => this.shareDialogShow(true)}>
          Share
          <Icon icon={shareIcon} className={classes.shareIcon} />
        </IconButton>

        <Dialog
          open={shareDialog.isShown}
          onClose={() => this.shareDialogShow(false)}
          aria-labelledby="form-dialog-title">
          <DialogContent>
            <DialogContentText>Share</DialogContentText>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <FacebookShareButton
                  url={url}
                  quote={title}
                  onShareWindowClose={() => this.shareDialogShow(false)}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </Grid>
              <Grid item>
                <TwitterShareButton
                  url={url}
                  title={title}
                  onShareWindowClose={() => this.shareDialogShow(false)}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </Grid>
              <Grid item>
                <LinkedinShareButton
                  url={url}
                  title={`Great ${type} posted on Zonotho`}
                  summary={title}
                  source={`Zonotho`}
                  onShareWindowClose={() => this.shareDialogShow(false)}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </Grid>
              <Grid item>
                <WhatsappShareButton
                  url={url}
                  title={title}
                  separator=": "
                  onShareWindowClose={() => this.shareDialogShow(false)}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.shareDialogShow(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  }
}

export default withStyles(styles)(Share);
