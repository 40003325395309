import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import {
  LinearProgress,
  CircularProgress,
  Typography,
  Grid
} from '@material-ui/core';

import { withFirebase } from '../Firebase';
import ArticleList from './ArticleList';
import {
  allArticlesSelector,
  limitSelector,
  categorySelector
} from './selectors';
import { changeUserCoins } from '../../common/Helpers';
import { ARTICLES } from '../../constants/routes';

const getGreetingTime = currentTime => {
  if (!currentTime || !currentTime.isValid()) {
    return 'Hello';
  }

  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 18; // 24hr time to split the evening
  const currentHour = parseFloat(currentTime.format('HH'));

  // Between 12 PM and 6PM
  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    return 'Good afternoon';
  }

  // Between 6PM and midnight
  if (currentHour >= splitEvening) {
    return 'Good evening';
  }

  // Between midnight and noon
  return 'Good morning';
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(16)
    }
  },
  pageTitle: {
    marginBottom: theme.spacing(3),
    fontWeight: 400,
    letterSpacing: 0.55,
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(5),
      fontSize: 30
    }
  },
  content: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      marginLeft: theme.spacing(40),
      marginRight: theme.spacing(40)
    }
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  moreButton: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(40),
      marginRight: theme.spacing(40)
    }
  },
  loadingMore: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const loadMore = 6;

class Articles extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingMore: false
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      if (!this.props.articles.length) {
        this.setState({ loading: true });
      }

      this.onListenForArticles();

      const messaging = this.props.firebase.messaging;
      if (messaging) {
        const that = this;

        messaging
          .requestPermission()
          .then(async function() {
            const token = await messaging.getToken();
            that.saveUserMessageToken(token);
          })
          .catch(function(err) {
            console.log('Unable to get permission to notify.', err);
          });

        navigator.serviceWorker.addEventListener('message', message =>
          console.log(message)
        );
      }
    }

    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(props) {
    if (this._isMounted) {
      if (props.category !== this.props.category) {
        this.setState({
          loading: true
        });
      }

      if (
        props.limit !== this.props.limit ||
        props.category !== this.props.category
      ) {
        this.onListenForArticles();
      }
    }
  }

  onListenForArticles = () => {
    this.unsubscribe = async function unsubscribe() {
      let query = this.props.firebase.articles();

      query = query.where('status', '==', 'published');

      if (this.props.category && this.props.category !== '') {
        query = query.where('category', '==', this.props.category);
      }

      const articlesRef = await query
        .orderBy('created', 'desc')
        .limit(this.props.limit)
        .get();

      if (articlesRef.docs.length > 0) {
        const articles = [];

        for (const articleRef of articlesRef.docs) {
          const article = articleRef.data();

          // eslint-disable-next-line array-callback-return
          const existingUser = articles.filter(art => {
            if (article.contributorRef === art.contributorRef) {
              return art;
            }
          });

          if (existingUser.length > 0) {
            article.contributor = existingUser[0].contributor;
          } else {
            const userRef = await this.props.firebase
              .user(article.contributorRef)
              .get();
            article.contributor = userRef.data();
          }

          articles.push({ ...article, uid: articleRef.id });
        }

        this.props.onSetArticles(articles);
      } else {
        this.props.onSetArticles([]);
      }

      this.setState({
        loading: false,
        loadingMore: false
      });
    };

    try {
      this.unsubscribe();
    } catch (error) {
      console.error(error);
    }
  };

  componentWillUnmount() {
    this._isMounted = false;

    this.unsubscribe();
    window.removeEventListener('scroll', this.handleScroll);
  }

  saveUserMessageToken = messageToken => {
    const { authUser, firebase, onSetAuthUser } = this.props;

    if (!authUser) return;

    const updatedUser = {
      ...authUser,
      messageToken
    };

    firebase
      .user(authUser.uid)
      .set(
        {
          messageToken
        },
        { merge: true }
      )
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  handleScroll = () => {
    const { loadingMore } = this.state;

    const isBottom =
      document.documentElement.scrollHeight - window.innerHeight <
      window.scrollY + 20;

    if (!isBottom || loadingMore) return;

    this.onLoadMore();
  };

  onLoadMore = () => {
    if (this._isMounted) {
      this.setState({
        loadingMore: true
      });
      this.props.onSetArticlesLimit(this.props.limit + loadMore);
    }
  };

  onLikeClick = articleId => {
    const { authUser, articles } = this.props;
    const article = articles.find(a => a.uid === articleId);

    if (!authUser) {
      this.props.showSighInDialog(
        { pathname: `${ARTICLES}/${article.uid}` },
        'like the post'
      );
      return;
    }

    if (!article.likes || article.likes.length === 0) {
      const newLikes = [authUser.uid];
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.increment.bind(this, article.contributorRef)
      );
      return;
    }

    if (article.likes.includes(authUser.uid)) {
      const newLikes = article.likes.filter(l => l !== authUser.uid);
      this.updateArticleLikes(
        articleId,
        newLikes,
        changeUserCoins.decrement.bind(this, article.contributorRef)
      );
      return;
    }

    const newLikes = [...article.likes, authUser.uid];
    this.updateArticleLikes(
      articleId,
      newLikes,
      changeUserCoins.increment.bind(this, article.contributorRef)
    );
  };

  updateArticleLikes(articleId, likes, doUpdateUserCoins) {
    const { articles, firebase, onSetArticles } = this.props;

    firebase
      .article(articleId)
      .set(
        {
          likes,
          likesCount: likes.length
        },
        { merge: true }
      )
      .then(() => {
        const newArticles = [...articles];
        const articleIndex = newArticles.findIndex(a => a.uid === articleId);
        if (articleIndex !== -1) {
          newArticles[articleIndex].likes = [...likes];
        }
        doUpdateUserCoins();
        onSetArticles(newArticles);
      })
      .catch(error => {
        console.log('error :', error);
        // this.setState({ error, openError: true });
      });
  }

  onSaveClick = articleId => {
    const { authUser, showSighInDialog } = this.props;

    if (!authUser) {
      showSighInDialog(
        { pathname: `${ARTICLES}/${articleId}` },
        'save the post'
      );
      return;
    }

    if (!authUser.savedArticles) {
      this.updateSavedArticles([articleId]);
      return;
    }

    const isSaved = authUser.savedArticles.includes(articleId);
    if (isSaved) {
      const articles = authUser.savedArticles.filter(a => a !== articleId);
      this.updateSavedArticles(articles);
      return;
    }

    const articles = [...authUser.savedArticles, articleId];
    this.updateSavedArticles(articles);
  };

  updateSavedArticles = articles => {
    const { authUser, firebase, onSetAuthUser } = this.props;
    const updatedUser = {
      ...authUser,
      savedArticles: [...articles]
    };

    firebase
      .user(authUser.uid)
      .set(
        {
          savedArticles: articles
        },
        { merge: true }
      )
      .then(() => {
        onSetAuthUser(updatedUser);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  deletePost = articleId => {
    const { firebase, articles, onSetArticles } = this.props;
    const article = articles.find(a => a.uid === articleId);

    if (!article) {
      console.error('There is not article');
      return;
    }

    firebase
      .article(articleId)
      .delete()
      .then(() => {
        this.cleanReferences({ ...article });
        const newArticles = articles.filter(a => a.uid !== articleId);

        onSetArticles(newArticles);
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  cleanReferences = article => {
    const { uid, contributorRef, banner, likes, comments } = article;

    if (banner) {
      this.deleteBanner(banner);
    }

    if (likes && likes.length > 0) {
      changeUserCoins.decrement.call(this, contributorRef, likes.length);
    }

    if (comments && Object.keys(comments).length > 0) {
      this.cleanLikesFromComments(comments);
    }

    this.cleanSavedArticles(uid);
  };

  deleteBanner = bannerUrl => {
    this.props.firebase.storage
      .refFromURL(bannerUrl)
      .delete()
      .then()
      .catch(err => {
        console.error('error: ', err);
      });
  };

  cleanLikesFromComments = comments => {
    const commentIds = Object.keys(comments);

    commentIds.forEach(id => {
      const { likes, user } = comments[id];
      if (likes && likes.length > 0) {
        changeUserCoins.decrement.call(this, user.uid, likes.length);
      }
    });
  };

  cleanSavedArticles = articleId => {
    const { firebase } = this.props;

    let query = firebase.users();
    query = query.where('savedArticles', 'array-contains', articleId);

    query
      .get()
      .then(snapshots => {
        snapshots.forEach(doc => {
          const userData = doc.data();
          const userId = doc.id;

          const newSavedArticles = userData.savedArticles.filter(
            id => id !== articleId
          );

          firebase.user(userId).update({
            savedArticles: newSavedArticles
          });
        });
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  render() {
    const { articles, classes, authUser } = this.props;
    const { loading, loadingMore } = this.state;
    const savedArticlesIds = (authUser && authUser.savedArticles) || [];
    const greetingTime = getGreetingTime(moment());
    const greeting = authUser
      ? `${greetingTime} ${authUser.firstName ||
          authUser.username}, here’s your latest feed…`
      : `${greetingTime}, log in or create a profile to view customised content`;
    const isAdmin = authUser && authUser.role === 'ADMIN';

    // const heading = category && category !== '' ? category : 'LATEST ARTICLES';

    return (
      <div>
        {loading && (
          <div>
            <LinearProgress />
          </div>
        )}

        <div className={classes.root}>
          <Grid item lg={12} md={12} xs={12} className={classes.content}>
            <Typography className={classes.pageTitle} variant="h1">
              {greeting}
            </Typography>
          </Grid>
          {!loading && (!articles || articles.length === 0) && (
            <div>
              {/* <Grid item lg={12} md={12} xs={12} className={classes.content}>
                <Typography align="left" gutterBottom variant="h3">
                  {heading}
                </Typography>

                <Divider className={classes.divider} />
              </Grid> */}

              <Grid item lg={12} md={12} xs={12} className={classes.content}>
                <Typography align="center" gutterBottom variant="body1">
                  There are no articles available
                </Typography>
              </Grid>
            </div>
          )}

          {!loading && articles && articles.length > 0 && (
            <div>
              {/* <Grid item lg={12} md={12} xs={12} className={classes.content}>
                <Typography align="left" gutterBottom variant="h3">
                  {heading}
                </Typography>

                <Divider className={classes.divider} />
              </Grid> */}

              <ArticleList
                userId={authUser ? authUser.uid : null}
                articles={articles}
                isEditable={isAdmin}
                savedArticlesIds={savedArticlesIds}
                onLikeClick={this.onLikeClick}
                onSaveClick={this.onSaveClick}
                onDeleteClick={this.deletePost}
              />
            </div>
          )}

          {loadingMore && (
            <div className={classes.loadingMore}>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className={classes.loadingMore}>
                <CircularProgress />
              </Grid>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  articles: allArticlesSelector(state),
  limit: limitSelector(state),
  category: categorySelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
  onSetArticles: articles => dispatch({ type: 'ARTICLES_SET', articles }),
  onSetArticlesLimit: limit => dispatch({ type: 'ARTICLES_LIMIT_SET', limit }),
  showAlert: alert => dispatch({ type: 'ALERT_SET', alert }),
  showSighInDialog: (locationState, message) =>
    dispatch({ type: 'SIGN_IN_DIALOG_VISIBLE', locationState, message })
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(styles)(Articles));
